import {Loader} from "@googlemaps/js-api-loader"

export default {
  install(app) {
    console.log("installing google map api")
    // list of libraries: https://developers.google.com/maps/documentation/javascript/libraries#libraries-for-dynamic-library-import
    const googleMapsApi = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      version: "weekly",
    })

    app.provide("googleMapsApi", googleMapsApi)
  },
}
