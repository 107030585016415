<script setup>
import LogoAtlaseekHub from "@/assets/img/logo/logo atlaseek hub en.svg"
import LogoUNTourism from "@/assets/img/logo-un-tourism.svg"
import LogoPhocuswright from "@/assets/img/logo-phocuswright.svg"
import sink from "@/sink/index.js"
import {useRouter} from "vue-router"

const router = useRouter()

const emit = defineEmits(["close"])

function onClickAtlaseekHub() {
  sink.event("clicked_popup_take_me_to_atlaseek_hub", {
    view: router.currentRoute.value.name,
  })
}

function onClickAtlaseekExplore() {
  sink.event("clicked_popup_stay_on_explore", {
    view: router.currentRoute.value.name,
  })
  emit("close")
}
</script>

<template>
  <div class="p-4 pt-8 md:pt-0">
    <div class="flex flex-row items-center gap-4">
      <h2 class="text-3xl font-semibold text-brown-800">Visit</h2>
      <LogoAtlaseekHub class="mt-1 h-auto w-52" />
    </div>

    <p class="mt-4 text-xl text-brown-700">
      The first creator marketplace for&nbsp;the&nbsp;travel industry
    </p>

    <!-- logos -->
    <div class="mt-4 flex flex-row justify-center gap-8 py-4 md:justify-start">
      <LogoUNTourism class="h-auto w-16 text-brown" />
      <LogoPhocuswright class="h-auto w-32 text-brown" />
    </div>

    <!-- buttons -->
    <div class="mt-8 flex flex-col gap-4 md:flex-row">
      <!-- CTA: hub -->
      <a
        class="inline-flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full bg-violet-500 px-4 py-2 transition-all duration-300 ease-in-out hover:bg-violet-300"
        href="https://hub.atlaseek.com"
        target="_blank"
        @click="onClickAtlaseekHub"
      >
        <span class="text-xl font-semibold text-white">take me to hub</span>
      </a>

      <!-- CTA: explore -->
      <div
        class="inline-flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full px-4 py-2 transition-all duration-300 ease-in-out hover:bg-peach-300"
        @click="onClickAtlaseekExplore"
      >
        <span class="text-xl font-semibold text-peach-500">stay on explore</span>
      </div>
    </div>
  </div>
</template>
