import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {
  collection,
  query,
  where,
  doc,
  serverTimestamp,
  updateDoc,
  addDoc,
  getDocs,
} from "firebase/firestore"
import {useUserStore} from "./user.js"

export const useArticlesSectionsStore = defineStore("articlesSections", () => {
  // stores
  const userStore = useUserStore()

  // state
  const sections = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const sectionsLoaded = computed(() => status.value == "loaded")

  // actions
  async function get(args) {
    const {article, ownerOnly, publishedOnly} = args || {}

    const articleId = article.id
    const collectionRef = collection(firestore, `articles/${articleId}/articleSections`)
    const userIsAdmin = userStore.userIsAdmin
    const userIsOwner = userStore.userId == article?.owner?.userId
    const queries = []

    // console.log({userIsAdmin, userIsOwner})

    if (ownerOnly || userIsOwner) {
      const userId = userStore.userId
      const belongsToCurrentUser = where("owner.userId", "==", userId)
      queries.push(belongsToCurrentUser)
    }

    if (publishedOnly) {
      const published = where("published", "==", true)
      queries.push(published)
    }

    // restrict by user plan if not admin or owner
    if (userIsAdmin === false && userIsOwner === false) {
      const userPlan = userStore.userPlan || "free" // signed out users have access to free sections
      const matchesUsersPlan = where("plans", "array-contains", userPlan)
      queries.push(matchesUsersPlan)
    }

    queries.push(where("meta.deleted", "==", false))

    // console.log({queries})

    const q = query(collectionRef, ...queries)
    const snapshot = await getDocs(q).catch((e) => console.error(e))
    const data = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))

    sections.value = data
    status.value = "loaded"
  }

  async function bind(args) {
    const {article, ownerOnly, publishedOnly} = args || {}

    const articleId = article.id
    const collectionRef = collection(firestore, `articles/${articleId}/articleSections`)
    const userIsAdmin = userStore.userIsAdmin
    const userIsOwner = userStore.userId == article.owner.userId
    const queries = []

    if (ownerOnly) {
      const userId = userStore.userId
      const belongsToCurrentUser = where("owner.userId", "==", userId)
      queries.push(belongsToCurrentUser)
    }

    if (publishedOnly) {
      const published = where("published", "==", true)
      queries.push(published)
    }

    if (!userIsAdmin && !userIsOwner) {
      const userPlan = userStore.userPlan || "free" // signed out users have access to free sections
      const matchesUsersPlan = where("plans", "array-contains", userPlan)
      queries.push(matchesUsersPlan)
    }

    queries.push(where("meta.deleted", "==", false))

    const q = query(collectionRef, ...queries)
    return await this.attach("sections", q)
  }

  async function unbind() {
    // console.log("unbind")
    return await this.detach()
  }

  async function create({articleId, data}) {
    console.log("create")
    const userId = userStore.userId
    const collectionRef = collection(firestore, `articles/${articleId}/articleSections`)
    const sectionData = {
      ...data,
      owner: {
        userId,
      },
      published: false,
      meta: {
        created: serverTimestamp(),
        updated: false,
        deleted: false,
      },
    }
    console.log({sectionData})
    return await addDoc(collectionRef, sectionData)
  }

  async function update({articleId, sectionId, data}) {
    console.log("update")
    const docRef = doc(firestore, `articles/${articleId}/articleSections/${sectionId}`)
    const updatedData = {
      ...data,
      "meta.updated": serverTimestamp(),
    }
    console.log({updatedData})
    return await updateDoc(docRef, updatedData)
  }

  async function remove({articleId, sectionId}) {
    console.log("delete")
    const docRef = doc(firestore, `articles/${articleId}/articleSections/${sectionId}`)
    const updatedData = {
      "meta.deleted": serverTimestamp(),
    }
    console.log({updatedData})
    return await updateDoc(docRef, updatedData)
  }

  return {
    // state
    sections,
    status,
    unsubscribe,

    // getters
    sectionsLoaded,

    // actions
    get,
    bind,
    unbind,
    create,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticlesSectionsStore, import.meta.hot))
}
