const prefix = ""

export default {
  install(app) {
    // register all components from components dir
    // https://vitejs.dev/guide/features.html#glob-import
    const modules = import.meta.glob("@/components/base/**/*.vue")

    for (const path in modules) {
      modules[path]().then((module) => {
        const componentName =
          prefix +
          path
            .split("/")
            .pop()
            .replace(/\.\w+$/, "")

        app.component(componentName, module.default || module)
      })
    }
  },
}
