<script setup>
import {OButton} from "@jirizavadil/framework.one/ui"
import {useAppStore} from "../stores/app"
import {onMounted, onUnmounted} from "vue"

const props = defineProps({
  showCloseButton: {
    type: Boolean,
    default: true,
  },
  padding: {
    type: Boolean,
    default: true,
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(["click-backdrop", "click-close"])

// stores
const appStore = useAppStore()

function onClickClose() {
  appStore.app.universalModal.active = false
  emit("click-close")
}

function onClickBackdrop(event) {
  if (event.target === event.currentTarget) {
    appStore.app.universalModal.active = false
    emit("click-backdrop")
  }
}

onMounted(() => {
  appStore.app.universalModal.active = true
})

onUnmounted(() => {
  appStore.app.universalModal.active = false
})
</script>

<template>
  <!-- background -->
  <div
    class="fixed inset-0 z-50 h-[100dvh] w-screen bg-black bg-opacity-50 flex-center"
    @click="onClickBackdrop"
  >
    <!-- modal -->
    <div
      class="relative mx-2 flex max-h-[98dvh] w-auto max-w-md flex-col gap-4 overflow-hidden rounded-3xl bg-sand-100 sm:max-w-xl md:mx-0 md:max-w-2xl xl:max-w-[60vw]"
      :class="[{'p-0': !padding}, {'p-4 md:p-8': padding}, {'overflow-y-scroll': scrollable}]"
    >
      <!-- close button -->
      <div class="absolute top-3 right-3 z-50 flex flex-row justify-end" v-if="showCloseButton">
        <!-- <ButtonX
          class="w-6 h-6 text-sand-300 hover:text-brown-400 cursor-pointer transition-all ease-in-out duration-300"
          @click="onClickClose"
        /> -->
        <OButton kind="naked" :icon="true" iconLucide="X" @action="onClickClose" />
      </div>

      <!-- content -->
      <div class="h-full w-full"><slot></slot></div>
    </div>
  </div>
</template>
