<script setup>
import {provide, ref, computed, defineAsyncComponent, watch} from "vue"
import {RouterView} from "vue-router"
import {auth} from "@/firebase"
import {onAuthStateChanged} from "firebase/auth"
import {useRoute, useRouter} from "vue-router"
import {useAppStore} from "./stores/app"
import {useUserStore} from "./stores/user.js"
import {useArticlesStore} from "./stores/articles.js"
import {useArticlesSectionsStore} from "./stores/articlesSections.js"
import MenuMain from "./components/menu/MenuMain.vue"
import {storeToRefs} from "pinia"
import {configureScope} from "@sentry/browser"
import content from "./content.js"
import FeedbackForm from "./components/FeedbackForm.vue"
import Notifications from "./components/Notifications.vue"
import sink from "./sink/index.js"

// this is consumed by ImageCDN in framework.one
const urlCDN = ref(null)
provide("FRAMEWORK_ONE_URL_CDN_IMAGES", urlCDN)

const route = useRoute()
const router = useRouter()

const debug = ref(false)
const AppDebug = defineAsyncComponent(() => import("./components/debug/AppDebug.vue"))

// stores
const appStore = useAppStore()
const userStore = useUserStore()
const articlesStore = useArticlesStore()
const articlesSectionsStore = useArticlesSectionsStore()

const {app} = storeToRefs(appStore)
const {userSignedIn, userLoaded} = storeToRefs(userStore)

const cmsData = ref(null)

const cmsPage = computed(() => {
  if (!cmsData.value) return
  return cmsData.value.items.find((item) => {
    // return item.meta?.model === "page" && item.fields?.slug === route.meta.contentKey
    return item.fields?.slug === route.meta.contentKey
  })
})

// sections that are not 'page'
const cmsGlobal = computed(() => {
  if (!cmsData.value) return
  // const sectionDirections = cmsData.value.items.find(
  //   (item) => item.meta?.model === "sectionDirections"
  // )
  // const faqCategories = cmsData.value.items.filter((item) => item.meta?.model === "faqCategory")
  // const faqs = cmsData.value.items.filter((item) => item.meta?.model === "faq")
  return {
    // sectionDirections,
    // faqCategories,
    // faqs,
  }
})

const cms = computed(() => {
  return {
    ...(cmsPage.value && {page: cmsPage.value}),
    ...(cmsGlobal.value && {global: cmsGlobal.value}),
  }
})

onAuthStateChanged(auth, async (firebaseUser) => {
  console.log("onAuthStateChanged()", {firebaseUser})
  if (firebaseUser && firebaseUser.email) {
    setSentry(firebaseUser)
    userStore.userSignedIn = true
    await userStore.bind({userId: firebaseUser.uid})

    // if user has no username -> redirect to sign up (new user)
    // if (!userStore.user.username) {
    //   console.log("user has no username -> redirecting to sign up")
    //   router.push({name: "SignUpView"})
    // }

    const redirectedFrom = router.currentRoute?.value?.redirectedFrom

    if (redirectedFrom && redirectedFrom.fullPath !== "/") {
      // console.log({redirectedFrom})
      // console.log("pushing to redirectedFrom")
      router.push(redirectedFrom)
    } else {
      // console.log("signed in, unbinding articles")
    }
  } else {
    userStore.userSignedIn = false
    // await userStore.unbind()
    // await articlesStore.unbind()
    // await articlesSectionsStore.unbind()
  }
})

function setSentry(firebaseUser) {
  if (import.meta.env.VITE_ENVIRONMENT == "local_dev") return

  configureScope((scope) => {
    scope.setUser({
      id: firebaseUser.uid,
      email: firebaseUser.email,
    })
  })
}

function onClickAtlaseekHub() {
  sink.event("clicked_banner_atlaseek_hub", {
    view: router.currentRoute.value.name,
  })
}

// fetch content
async function fetchContent() {
  const url = `https://static.platforma.one/orgs/atlaseek/spaces/atlaseek.com/environments/main/locales/en-us/site.json`
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache", // TODO: remove this?
  })
  const data = await response.json()
  cmsData.value = data

  if (data?.cdn?.images) {
    urlCDN.value = data.cdn.images
  }
  console.log(`generated: ${new Date(data.generated)}`)
}

// TODO: when switching away from discover, set meta theme-color to: #F3EBE1
watch(
  () => app.value.body.fixed,
  (newValue, oldValue) => {
    if (newValue) {
      document.body.classList.add("fixed")
    } else {
      document.body.classList.remove("fixed")
    }
  },
  {immediate: true}
)

watch(
  () => app.value.body.blackBackground,
  (newValue, oldValue) => {
    if (newValue) {
      document.documentElement.style.backgroundColor = "#000000"
    } else {
      document.documentElement.style.backgroundColor = "#F3EBE1" // sand-DEFAULT
    }
  },
  {immediate: true}
)

fetchContent()
</script>

<template>
  <div class="">
    <!--<div
      class="fixed -right-[75px] top-[10px] z-50 rotate-45 bg-brown-700 px-20 py-1 uppercase text-white"
    >
      beta
    </div>-->

    <!-- atlaseek hub -->
    <div class="fixed left-0 right-0 z-50 flex bg-violet-500 px-3 py-1 text-white flex-center">
      <p class="">
        hub:
        <a
          class="underline"
          href="https://hub.atlaseek.com"
          target="_blank"
          @click="onClickAtlaseekHub"
          >collaborate with best travel creators</a
        >
      </p>
    </div>

    <!--<FeedbackForm class="" />-->
    <Notifications />

    <div>
      <MenuMain
        class="fixed right-0 z-30 flex w-full flex-row justify-center px-4 pb-4 transition-all duration-500 ease-in-out md:right-auto md:top-0 md:h-screen md:w-16 md:flex-col md:px-0 md:pb-0"
        :class="[
          {'pwa:bottom-1 bottom-0 md:left-0': app.menu.visible},
          {'-bottom-[200px] md:-left-[80px]': !app.menu.visible},
        ]"
        v-if="!['BlogView', 'BlogPostView'].includes(router.currentRoute.value.name)"
      />
      <div
        class="pt-8"
        :class="[{'md:ml-16': !['BlogView', 'BlogPostView'].includes($route.name)}]"
      >
        <RouterView :content="content.content.en" :cms />
      </div>
    </div>

    <AppDebug class="fixed left-2 top-2 z-[99999]" v-if="debug" />
  </div>
</template>

<style>
body.fixed {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
</style>
