import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc, query, where, setDoc, collection, serverTimestamp, updateDoc} from "firebase/firestore"
import {useUserStore} from "./user.js"
import {useAppStore} from "./app.js"
import {useRoute} from "vue-router"
import orderBy from "lodash/orderBy"

export const useArticlesStore = defineStore("articles", () => {
  // helpers
  const route = useRoute()
  const countrySlug = computed(() => route.params.countrySlug)
  const locationSlug = computed(() => route.params.locationSlug)

  // stores
  const userStore = useUserStore()
  const appStore = useAppStore()

  // state
  const articles = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const articlesLoaded = computed(() => status.value == "loaded")
  const articlesSorted = computed(() => {
    const TEN_DAYS_IN_MS = 864000000 // 10 * 24 * 60 * 60 * 1000
    const MONTH_IN_MS = 2419200000 // 28 * 24 * 60 * 60 * 1000
    const now = Date.now()
    // const godfathers = ["LsmukC9m7fDg3bhVFT5BBz4Hul4k"] // dev
    const godfathers = [
      "2oDykJQKYrV8NY8Z6gP03Fe71O82",
      "LFhSjn2EnffqKOnFzmPySiaNZHC2",
      "P6lkE4jV9XeZvwj5mXLYQwSNJaM2",
      "SSY1AOm8bgWo6Mx5hBLb0Rf5YOA3",
      "h2J61IFMyoOztWT64yySbDbQAEA3",
      "m07STyTcLKOsab3nydC1BA8z0bB3",
      "sdawYrchqJTlBBHax1rInld3Lw82",
    ]

    // 1. free, if less than month old, descending by created date
    // 2. created in last 10 days, descending by created date
    // 3. from godfathers, descending by created date
    // 4. everything else descending by created date

    return orderBy(
      articles.value,
      [
        (article) => {
          if (
            now - article.meta?.created?.toMillis() <= MONTH_IN_MS &&
            !article.hasPremiumSections
          ) {
            return 0 // free and less than a month old
          }
          if (now - article.meta?.created?.toMillis() <= TEN_DAYS_IN_MS) {
            return 1 // less than 10 days old
          }
          if (godfathers.includes(article.owner.userId)) {
            return 2 // by godfathers
          }
          return 3 // everything else
        },
        (article) => article.meta?.created?.toMillis(),
      ],
      ["asc", "desc"]
    )

    // return orderBy(articles.value, ["hasPremiumSections", "meta.created"], ["asc", "desc"])
  })
  const articlesAtSelectedLocation = computed(() => {
    if (!articlesSorted.value) return []

    return articlesSorted.value.filter((article) => {
      // filter by country & location
      if (countrySlug.value && locationSlug.value) {
        return (
          article.countriesSlugs?.includes(countrySlug.value) &&
          article.locationsSlugs?.includes(locationSlug.value)
        )
      }
      // filter by country
      else if (countrySlug.value) {
        return article.countriesSlugs?.includes(countrySlug.value)
      }
      // no filter
      else {
        return true
      }
    })
  })
  const articlesAtSelectedLocationCount = computed(() => articlesAtSelectedLocation.value.length)

  // actions
  async function bind(args) {
    const {currentUser = false, publishedOnly = false} = args || {}
    // console.log("binding articles:")

    const collectionRef = collection(firestore, `articles`)

    const queries = []
    const notDeleted = where("meta.deleted", "==", false)
    queries.push(notDeleted)

    if (currentUser) {
      const userId = userStore.userId
      const belongsToCurrentUser = where("owner.userId", "==", userId)
      queries.push(belongsToCurrentUser)
    }

    if (publishedOnly) {
      const published = where("published", "==", true)
      queries.push(published)
    }

    const q = query(collectionRef, ...queries)
    return await this.attach("articles", q)
  }

  async function unbind() {
    // console.log("unbind")
    return await this.detach()
  }

  async function create() {
    // console.log("create")
    const userId = userStore.userId
    const collectionRef = collection(firestore, `articles`)
    const docRef = doc(collectionRef)
    const data = {
      owner: {
        userId,
      },
      // for now authors are automatically set to the owner
      authors: [
        {
          userId,
          name: userStore.user.name,
          username: userStore.user.username,
        },
      ],
      published: false,
      meta: {
        created: serverTimestamp(),
        updated: false,
        deleted: false,
      },
    }
    // console.log({data})
    await setDoc(docRef, data)

    return {id: docRef.id}
  }

  async function update({articleId, data}) {
    // console.log("update", data)
    const docRef = doc(firestore, `articles/${articleId}`)
    const updatedData = {
      ...data,
      "meta.updated": serverTimestamp(),
    }
    // console.log({updatedData})
    return await updateDoc(docRef, updatedData)
  }

  async function remove({articleId}) {
    // console.log("remove")
    const docRef = doc(firestore, `articles/${articleId}`)
    const data = {
      "meta.deleted": serverTimestamp(),
    }
    // console.log({data})
    return await updateDoc(docRef, data)
  }

  return {
    // state
    articles,
    status,
    unsubscribe,

    // getters
    articlesLoaded,
    articlesSorted,
    articlesAtSelectedLocation,
    articlesAtSelectedLocationCount,

    // actions
    bind,
    unbind,
    update,
    create,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticlesStore, import.meta.hot))
}
