const content = {
  en: {
    roadmap: {
      title: "Roadmap",
      intro: `Atlaseek is still in its early stage. We launched in August 2023 and continuously bring new creators on board and build a better experience for curious travelers like you. `,
      listTitle: "What's up next in our roadmap:",
      list: [
        {id: 1, text: "watch creator youtube videos one week earlier"},
        {
          id: 2,
          text: "interact with articles (comments, up/down votes)",
        },
        {id: 3, text: "download pins to your google maps"},
        {
          id: 4,
          text: "search",
        },
        {id: 5, text: "feed with new and recommended content"},
        {
          id: 6,
          text: "notifications",
        },
        {
          id: 7,
          text: "map flyover tours",
        },
        {
          id: 8,
          text: "personal maps for friends & family sharing",
        },
        {
          id: 9,
          text: "chat community & 1 on 1 with creators",
        },
        {
          id: 10,
          text: "mobile app",
        },
      ],
      closing:
        "Follow us on [instagram], where we share updates and let you vote about our next priority and reveal behind the scene of building atlaseek.\n\nHave any suggestion or feedback to share? I'd love to hear from you - reach out to me at [emailKristina].\n\nKristina, founder",
    },
    faq: {
      title: "FAQ",
      sections: [
        {
          id: "about",
          title: "About",
          questions: [
            {
              question: "Why isn't atlaseek free?",
              answer:
                "Atlaseek is partially free - some content, full or its parts, is visible to everyone. So that you know what kind of stories and travel tips you can enjoy here and decide if you want to be a part of this community.\nCreators spend great amount of time, energy, money to travel the world and share the tips and stories with all of us. They either have to accept a lot of brand deals and promotions, or get support from their community. And we believe the second option is better and more sustainable for both parties. That's why, to see all content, you have to get one of our paid plans. Creators split 50% of the plan amount based on how much people engage with their content. So by subscribing, you're supporting the whole atlaseek creators community, encouraging them to create an even better, ads free content for you. And of course, you're also supporting us, the atlaseek team, to run this platform and constantly bring new features.",
            },
            {
              question: "Why should I pay for creator content?",
              answer:
                "On the internet, no content is free. You consume ads, read sponsored tips and never know exactly, what's really honest. But not with atlaseek. When you get one of our paid plans, you not only get access to exclusive tips and recommendations from experienced travelers and creators, but you also wave goodbye to those annoying ads and sponsored content. Plus, by supporting creators through our paid plan, you help them keep doing what they love while delivering top-notch content to you. So, go ahead, treat yourself to ad-free adventures and support the travel community at the same time!",
            },
            {
              question: "How many creators are part of atlaseek?",
              answer:
                "The creator team is constantly growing, you can find the whole list on our home page. We want to have a diverse and big team of creators, while prioritizing quality over quantity. You'll never find thousands of creators on atlaseek. But we'll make sure you'll have access and support the best of the best. If you are missing a travel creator you love here, please let them know and share atlaseek with them - maybe they didn't spot us yet and would like to join!",
            },
            {
              question: "How does atlaseek make money?",
              answer:
                "We are a subscription based platform and split 50% with all creators.\nWe carefully track watch and read time of each paying user and based on that distribute the profit among creators. So, the more you watch and read content from a certain creator, the bigger share they get.",
            },
          ],
        },

        {
          id: "subscription",
          title: "Subscription",
          questions: [
            {
              question: "Can I cancel my subscription?",
              answer:
                "Of course, you can do that any time in your account settings. Once you cancel, your plan will be active until the end of current period and you won't be charged for the next one. If you have any issues with your subscription, please connect with us hey@atlaseek.com.",
            },
            {
              question: "Do you have a free trial or a free plan?",
              answer:
                "We have a free plan that allows you to browse our map, see preview of all content and access articles creators decided to publish as free. We don't offer a free trial.\nPlease remember all our content is ads free, so you're not supporting creators under the free plan. To enjoy full content and support all creators with a single subscription, please select one of the paid plans.",
            },
            {
              question: "Can I get a refund?",
              answer:
                "You can unsubscribe from your plan any time, however the amount isn't refundable.\nPlease get in touch with us directly at <a href='mailto:hey@atlaseek.com'>hey@atlaseek.com</a> to understand your feedback and how can we improve.",
            },
            {
              question: "Can I get a discount?",
              answer: "Sorry, we're not offering any discount to our plans.",
            },
          ],
        },
        {
          id: "forCreators",
          title: "For Creators",
          questions: [
            {
              question: "What type of content should I share on atlaseek?",
              answer:
                "Our explorers mostly enjoy detailed guides, trip reports and unique travel experiences as a video/article. The key is being authentic and only share what you experienced and liked - no ads and affiliates. In an exceptional situation, you can also use content you already created, if it's really great. However the main purpose of people supporting you on atlaseek is to get exclusive content, so most of your content must be unique, not accessible for free elsewhere (see 'Does the content have to be unique?'). Create articles, share them with your community on your social media and see how they perform. Stick to what works best for you and the audience.",
            },
            {
              question: "How often should I post?",
              answer:
                "Quality is more than quantity. We don't push creators to publish under a schedule, we know you cannot dictate creativity. However to keep our community engaged and make the most out of being atlaseek creator, we recommend at least 1-2 articles a month. Make sure you promote your content on atlaseek with you community and use your affiliate link to increase discoverability.",
            },
            {
              question: "Does the content have to be unique?",
              answer:
                "Majority of your atlaseek content has to be exclusive and unique. If you'd be offering content that's freely accessible elsewhere but is paid on atlaseek, it would cause more harm than good to both yours and our brand. Always keep the paying customer in mind and remember they came to support you and the community and get unique, exclusive content. In exceptional occasions, you can repurpose some high quality content, but you should think if there's some additional value you can add to make it worth for the paying user.",
            },
            {
              question: "How can I join as a creator?",
              answer:
                "You can either get recommended by one of the current atlaseek creators or apply through our creator sign up page. Please note that we're handpicking each creator and your profile will be evaluated (see 'How do you choose creators for atlaseek?').",
            },
            {
              question: "How do you choose creators for atlaseek?",
              answer:
                "We're handpicking each new creator or using recommendations from the current ones. We are transparent on what are the main criteria to accept a creator:\n1. high quality, engaging and detailed travel content from places they personally been to, featuring local stories, hidden gems and cultural respect. Ads/affiliate free content on atlaseek only.\n2. engaging, well sized audience on their social media (mainly instagram, youtube, tik tok)\n\nIn the future, we want to give a chance also to rising creators - huge following isn't everything. Get in touch with us to discuss how we can make it happen.",
            },
            {
              question: "How do you pay creators?",
              answer:
                "All atlaseek creators split 50% of our total subscription profit each month, based on paid user engagement with their content (watch and read time). You can request a payout once your amount exceeds $250. You'll have all data and statistics in your creator dashboard.",
            },
            {
              question: "What is Watch and read time and how is it calculated?",
              answer:
                "We track watch and read time (WRT) of every signed in user on a paid plan. In short, it's the time a user spends reading creator's content or watches video and photos. It's the key metric to process payouts to creators -> the more engaging content you create, the more time will people spend with it and the bigger will your payout be.\n\nWe track WRT as user's active time the within article window (scrolling, mouse movement, clicks & video playback). We count activity in 10s interval and stop after 30s of inactivity.\n\nWRT per user per article cannot be greater than the article's read time (visible next to author in article detail). This is reset every 1st day of month at 00:00 am UTC.\n\nIn case a subscribed user won't have any WRT in a given month, we distribute 50% of their plan value among all creators evenly. ",
            },
            {
              question: "What is read time and how is it calculated?",
              answer:
                "Article read time is the sum of time it takes to read the article and watch its videos.\nCalculated as reading speed 265 words per minute.\nImages add an additional 12 seconds for the first image, 11 seconds for the second image, and minus an additional second for each subsequent image, through the tenth image. Any images after the tenth image are counted at three seconds.\nVideo is counted with the full video length.",
            },
          ],
        },
        {
          id: "creatorcalls",
          title: "Consultation Calls with Creators",
          questions: [
            {
              question: "What is a creator call?",
              answer:
                "It's a call with your favorite travel creator to discuss topics you'd like to learn from them, based on their offering (usually around travel and content creation). Each creator has own knowledge and expertise they can consult, at different price.",
            },
            {
              question: "What happens after I buy the creator call?",
              answer:
                "After booking, you'll get our confirmation and creators will reach out over email to fix the date and time of your call (based on your preferences and their availability) and share the calendar invite with you. They might also ask additional questions regarding your expectations to prepare for the call with you. \nJoin the call at the agreed time, ask questions and get high-quality advice from your favorite creator.",
            },
            {
              question: "How do I select the creator call time?",
              answer:
                "Creator will follow up over email after you book the call (within 24hrs) to fix the date and time of your call (based on your preferences and their availability) and share the calendar invite with you.\nIn case of any troubles, you can always reach out to atlaseek for support at hey@atlaseek.com.",
            },
            {
              question: "Can I reschedule or cancel the creator call?",
              answer: "You can reschedule the call at least 48 hours before the session. Email the creator directly to choose a new time that works for all.\nYou'll get their email address in our booking confirmation PDF.",
            },
            {
              question: "What if I'm not happy with the service?",
              answer: "We're confident our creator deliver amazing consulting services, however in case of dissatisfaction, please reach out to hey@atlaseek.com to make things right.",
            },
          ],
        },
        {
          id: "pressSponsors",
          title: "For Press & Sponsors",
          questions: [
            {
              question: "What's the tech stack behind atlaseek?",
              answer:
                "We run on Google Cloud Platform, Firebase, NodeJS, VueJS and CesiumJS with Sentinel-2 & Bing Maps imagery.",
            },
            {
              question: "Can I advertise on atlaseek?",
              answer: "Atlaseek isn't an advertisement platform. We don't allow affiliates or ads.",
            },
            {
              question: "Can I write about atlaseek?",
              answer:
                "Of course, we'd love you to share atlaseek with the world! To make it easier for you, check our <a target='_blank' href='https://atlaseek.com/press-kit'>press kit</a> and use it to get some cool content. If you have any specific questions, please reach out to <a href='mailto:hey@atlaseek.com'>hey@atlaseek.com</a>. Please always link our website when referring to us and use the correct name 'atlaseek' (small 'a', one 's').",
            },
          ],
        },
      ],
    },
    team: {
      title: "Team",
      titleGodfathers: "Godfathers - atlaseek builders team",
      godfathers: [
        {
          id: 1,
          name: "@OskarAndDan",
          img: "https://cdn.atlaseek.com/images_profile/oskaranddan.png",
          url: "https://atlaseek.com/@OskarAndDan",
        },
        {
          id: 2,
          name: "@JourneyWithJarv",
          img: "https://cdn.atlaseek.com/images_profile/journeywithjarv.png",
          url: "https://atlaseek.com/@JourneyWithJarv",
        },
        {
          id: 3,
          name: "@JosieLiftsThings",
          img: "https://cdn.atlaseek.com/images_profile/josieliftsthings.png",
          url: "https://atlaseek.com/@JosieLiftsThings",
        },
        {
          id: 4,
          name: "@SenseTheLens",
          img: "https://cdn.atlaseek.com/images_profile/sensethelens.png",
          url: "https://atlaseek.com/@SenseTheLens",
        },
        {
          id: 5,
          name: "@sanjay1",
          label: "169 countries",
          img: "https://cdn.atlaseek.com/images_profile/sanjay1.png",
          url: "https://atlaseek.com/@sanjay1",
        },
        {
          id: 6,
          name: "@dnzh.travels",
          label: "114 countries",
          img: "https://cdn.atlaseek.com/images_profile/dnzh.travels.png",
          url: "https://atlaseek.com/@dnzh.travels",
        },
        {
          id: 7,
          name: "@petekphilippe",
          label: "153 countries",
          img: "https://cdn.atlaseek.com/images_profile/petekphilippe.png",
          url: "https://atlaseek.com/@petekphilippe",
        },
      ],
      titleCore: "Core team",
      core: [
        {
          id: 1,
          name: "Kristina",
          role: "founder",
          img: "/img/kristina.jpg",
          links: [
            {id: 1, kind: "email", email: "kristina@atlaseek.com"},
            {id: 2, kind: "linkedin", url: "https://linkedin.com/in/kristinamelsova"},
          ],
          paragraph:
            "Unfortunately not a full time traveler, but passionate about exploring our little planet and showing it to everyone through storytelling. A girl that used to build tech and product teams and digital products in the corporate world, escaped the 9 to 5 and finally is building her own product.",
        },
        {
          id: 2,
          name: "George",
          role: "keyboard operator",
          img: "/img/george.jpg",
          links: [
            {id: 1, kind: "email", email: "jiri@zavadil.pro"},
            {id: 2, kind: "linkedin", url: "https://linkedin.com/in/jirizavadil"},
          ],
          paragraph:
            "I'm a software engineer and designer who simplifies through tech and creates sleek designs. I build ecommerce and web projects and love to explore new destinations on vacation by renting a car and discovering places that are off the beaten path. * this description was generated by GPT-4 because I don't like describing myself 👨🏻‍💻",
        },
      ],
    },
    presskit: {
      sections: [
        {
          id: 1,
          paragraph:
            "Hey, I'm Kristina, founder of atlaseek, thanks for stopping by! This site is to help you share atlaseek with the world. Thank you for supporting us!",
        },
        {
          id: 2,
          title: "mission",
          paragraph:
            "Our mission is to unite the best travel creators and travelers on a single platform. Making supporting creators easy, giving them freedom from algorithms and freedom to tell the most inspiring stories from every country in the world.",
        },
        {
          id: 3,
          title: "problem description",
          paragraph:
            "We're leveraging the best from the creator economy and the travel industry.\n75% of young travelers find inspiration from social media, and influencers have become the new travel guides.\n50% of social media users are keen to support creators, but have to cherry pick 1-2 individual memberships.\nWith Gen Z becoming obsessed by hunting hidden gems and already traveling 29 days a year, the role of social media will become even more crucial for destinations. And finding reliable creators is becoming increasingly challenging for travel businesses.\n\nWe believe the future of social networks lies in vertical, niche platforms that are extremely relevant and community-driven. That's where atlaseek comes in.",
        },
        {
          id: 4,
          title: "product description",
          list: [
            "map of the world with all creators content in one place (unique guides, stories, videos, early releases and bonus content)",
            "content from the most fascinating places on our planet,  by the best storytellers",
            "1:1 consultations with creators about travel or content creation",
            "chat community for travelers and creators to connect on relevant topics",
            "feed for keeping up to date with favorite creators and discover new ones",
            "single subscription supporting all creators based on Watch and Read Time (WRT)",
            "…and more to come",
          ],
        },
        {
          id: 5,
          paragraph:
            "Atlaseek is completely ads- and affiliate-free. All recommendations on our platform are real and driven by handpicked creators, not manipulated by algorithms.\n\nCombination of unique written and video content, chats, 1:1 consultation and more is enabling fans to get the highest value when supporting travel creators & creators monetize their knowledge and talent easier as a team than as individuals.\n\nAtlaseek has tens of handpicked, verified travel creators, who are sharing the best of their experiences from every country in the world. Combined reach of our creators is in millions of followers.",
        },
        {
          id: 6,
          title: "roadmap",
          paragraph:
            "We run as beta with features being added incrementally. Follow us on instagram for regular updates.",
        },
        {
          id: "spacer-1",
          spacer: true,
        },
        {
          id: 8,
          title: "logo",
          images: [
            {
              id: 1,
              src: "/presskit/logo.jpg",
              label: "logo",
            },
            {
              id: 2,
              src: "/presskit/icon.jpg",
              label: "icon",
            },
          ],
        },
        {
          id: 9,
          title: "peek at atlaseek",
          images: [
            {
              id: 3,
              src: "/presskit/atlaseek-landing.jpg",
              label: "landing",
            },
            {
              id: 4,
              src: "/presskit/atlaseek-in-bio.jpg",
              label: "creator in-bio",
            },
            {
              id: 5,
              src: "/presskit/atlaseek-articles.jpg",
              label: "articles",
            },
            {
              id: 6,
              src: "/presskit/atlaseek-discover.jpg",
              label: "discover",
            },
          ],
        },
        {
          id: 10,
          // title: "download press kit",
          button: {
            id: 1,
            title: "download all media",
            href: "/presskit/atlaseek_press_kit_v2.zip",
            clickEvent: "download_all_media",
          },
        },
        {
          id: "spacer-2",
          spacer: true,
        },
        {
          id: 11,
          title: "contact",
          links: [
            {
              id: 1,
              title: "hey@atlaseek.com",
              href: "mailto:hey@atlaseek.com",
            },
          ],
        },

        {
          id: 12,
          title: "links",
          links: [
            {
              id: 1,
              title: "atlaseek.com",
              href: "https://atlaseek.com",
            },
            {
              id: 2,
              title: "instagram.com/atlaseek",
              href: "https://instagram.com/atlaseek",
            },
            {
              id: 3,
              title: "linkedin.com/company/atlaseek",
              href: "https://linkedin.com/company/atlaseek",
            },
            {
              id: 3,
              title: "linkedin.com/in/kristinamelsova",
              href: "https://www.linkedin.com/in/kristinamelsova",
            },
            {
              id: 3,
              title: "linkedin.com/in/jirizavadil",
              href: "https://www.linkedin.com/in/jirizavadil",
            },
          ],
        },
        {
          id: 13,
          title: "general information",
          list: [
            "founded by Kristina Melsova and Jiri Zavadil",
            "launched in May 2023 in Dubai, UAE",
            "company name: PLGRND L.L.C-FZ",
            "company ID: 2308694",
          ],
        },
      ],
    },
    applyAsCreator: {
      title: "Apply as creator",
      paragraph:
        "For professional creators only. Quality is more than quantity for us, get in touch to discuss more.",
      lists: [
        {
          id: 1,
          listTitle: "What's in it for you",
          list: [
            {
              id: 1,
              text: "monetize your travel knowledge through unique guides, travel tips, stories and videos",
            },
            {
              id: 2,
              text: "make it easier for your fans to support you thanks to a single subscription that supports all atlaseek creators",
            },
            {
              id: 3,
              text: "creators split 50% of the total subscription revenue - potential to earn more than with an individual subscription plan",
            },
            {
              id: 4,
              text: "boost your reach and join a creator community with combined following of millions curious travelers",
            },
            {
              id: 5,
              text: "cross sell other high quality travel products like 1:1 consultations, tours and more",
            },
            {
              id: 6,
              text: "unlock new business opportunities through collaboration with government travel authorities",
            },
          ],
        },
        {
          id: 2,
          listTitle: "Main criteria to accept a creator",
          list: [
            {
              id: 1,
              text: "high quality and detailed travel content from places you personally been to, featuring local stories, hidden gems and cultural respect, ads/affiliate free content on atlaseek only",
            },
            {
              id: 2,
              text: "well balanced content quality, engagement and audience size. This could range from 5k to @christiano.",
            },
          ],
        },
      ],
      alreadyCreator: {
        paragraph: "seems like you already made it to the list 😜",
      },
      alreadyApplied: {
        paragraph: "seems like you already applied 🤩",
      },
      success: {
        title: "Time to check your inbox 📬",
        paragraph:
          "Thanks for being interested in atlaseek. Check our e-mail and we'll get back to you within 24 hours to discuss next steps.",
      },
    },
  },
}

const base = {
  locales: [
    {
      id: "en",
      title: "english",
    },
  ],
  content,
}

export default base
