// call method when element becomes visible in viewport

// usage:
// <div v-visible:once="onVisible"></div>
//
// methods: {
//   onVisible(isVisible, entry) {}
// }

export default {
  install: (app) => {
    app.directive("visible", {
      beforeMount: function beforeMount(targets, binding) {
        let onceFired = false // when firing only once
        let options = {
          root: null,
          // rootMargin: '0px',
          // threshold: 1.0
        }

        let observer = new IntersectionObserver((entries) => {
          if (binding.arg == "once" && onceFired) return

          let entry = entries[0] // there is always only one entry
          let isVisible = entry.isIntersecting

          if (binding.arg == "once" && isVisible) onceFired = true

          if (binding.value) {
            binding.value(isVisible, entry)
          }
        }, options)

        observer.observe(targets)
      },
    })
  },
}
