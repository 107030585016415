<script setup>
import MenuSignIn from "./MenuSignIn.vue"
import {ref, computed} from "vue"
import {auth} from "@/firebase"
import {signOut} from "@firebase/auth"
import {useAppStore} from "../../stores/app"
import {useUserStore} from "../../stores/user"
import {storeToRefs} from "pinia"
import {OneButton} from "@jirizavadil/framework.one/ui"
import {useRoute, useRouter} from "vue-router"
import MenuSignInAs from "./MenuSignInAs.vue"

const route = useRoute()
const router = useRouter()

const emit = defineEmits(["menuItemClicked"])

const appStore = useAppStore()
const userStore = useUserStore()
const {app} = storeToRefs(appStore)
const {user, userIsAdmin} = storeToRefs(userStore)
const {userAllowed} = userStore

const showSignInAsUserField = ref(false)
const selectedMenuItemParent = ref(false)

const menuItems = ref([
  {
    id: "roadmap",
    label: "roadmap",
    icon: "Map",
    route: {name: "RoadmapView"},
  },
  {
    id: "faq",
    label: "FAQ",
    icon: "HelpCircle",
    route: {name: "FaqView"},
  },
  // {
  //   id: "plans",
  //   label: "plans",
  //   icon: "Gift",
  //   route: {name: "PlansView"},
  // },
  {
    id: "reload",
    label: "reload",
    icon: "RefreshCw",
    action: "reload",
  },
  {
    id: "more",
    label: "more",
    icon: "MoreHorizontal",
    children: [
      {
        id: "instagram",
        label: "instagram",
        icon: "Instagram",
        link: {href: "https://instagram.com/atlaseek", target: "_blank"},
      },
      {
        id: "email",
        label: "hey@atlaseek.com",
        icon: "AtSign",
        link: {href: "mailto:hey@atlaseek.com"},
      },
      {
        id: "team",
        label: "team",
        icon: "Star",
        route: {name: "TeamView"},
      },
      {
        id: "press-kit",
        label: "press kit",
        icon: "Presentation",
        route: {name: "PressKitView"},
      },
      {
        id: "terms",
        label: "boring stuff",
        icon: "Scale",
        route: {name: "TermsView"},
      },
    ],
  },
  {
    id: "hr-1",
    hr: true,
    roles: ["admin", "exploreCreator", "exploreExplorer"],
  },
  {
    id: "articles",
    label: "articles",
    icon: "Newspaper",
    route: {name: "ArticlesView"},
    roles: ["admin", "exploreCreator", "exploreExplorer"],
  },
  {
    id: "articleManager",
    label: "article manager",
    icon: "FileEdit",
    route: {name: "ArticleManagerView"},
    roles: ["admin", "exploreCreator"],
  },
  {
    id: "signInAs",
    label: "sign in as",
    icon: "Users",
    action: "signInAs",
    roles: ["admin"],
  },
  {
    id: "settings",
    label: "settings",
    icon: "Settings",
    route: {name: "SettingsView"},
    roles: ["admin", "exploreCreator", "exploreExplorer"],
    children: [
      {
        id: "myPlan",
        label: "my plan",
        icon: "Gem",
        route: {name: "SettingsPlanView"},
        roles: ["admin", "exploreCreator", "exploreExplorer"],
      },
      {
        id: "admin",
        label: "admin",
        icon: "ShieldAlert",
        route: {name: "SettingsAdminView"},
        roles: ["admin"],
      },
      {
        id: "signOut",
        label: "sign out",
        icon: "LogOut",
        variant: "error",
        route: {name: "SettingsView"},
        action: "logout",
        roles: ["admin", "exploreCreator", "exploreExplorer"],
      },
    ],
  },
])

const menuItemsFiltered = computed(() => {
  if (selectedMenuItemParent.value) {
    return selectedMenuItemParent.value.children.filter((item) =>
      item.roles ? userAllowed(item.roles) : true
    )
  } else {
    return menuItems.value.filter((item) => {
      let pass = true

      // check user roles
      pass = item.roles ? userAllowed(item.roles) : true

      // check if app is running in PWA mode
      if (item.id === "reload") {
        pass = app.value.pwa.running
      }
      return pass
    })
  }
})

function onMenuItemClick(item) {
  // if item has children -> show children
  if (item.children) {
    selectedMenuItemParent.value = item
  }

  // if item has action -> execute action
  else if (item.action) {
    // logout
    if (item.action == "logout") {
      logout()
    }
    // sign in as
    else if (item.action == "signInAs") {
      showSignInAsUserField.value = !showSignInAsUserField.value
    }
    // reload
    else if (item.action == "reload") {
      location.reload()
    }
  }
  // go to route
  else if (item.route) {
    router.push(item.route)
    emit("menuItemClicked", item)
  }

  // open link
  else if (item.link) {
    window.open(item.link.href, item.link.target || "_self")
    emit("menuItemClicked", item)
  }
}

function menuBack() {
  selectedMenuItemParent.value = false
}

function logout() {
  signOut(auth)
  userStore.unbind()
  selectedMenuItemParent.value = false

  if (route.meta.requiresAuth) {
    router.push({name: "DiscoverView"})
  }
}
</script>

<template>
  <div class="min-w-[300px]">
    <div class="w-full rounded-2xl bg-brown-700 px-4 pb-4">
      <!-- signed in: user info -->
      <div v-if="user.id">
        <!-- user info -->
        <div class="p-4">
          <span class="text-center" v-if="user.username">@{{ user.username }}</span>
          <span class="block">{{ user.email }}</span>
          <!-- <span class="block">roles: {{ user.roles.toString() }}</span> -->
          <span class="block">plan: {{ user.plan }}</span>
        </div>
      </div>

      <!-- menu items -->
      <div class="pt-4">
        <div class="py-1" v-for="item in menuItemsFiltered" :key="item.id">
          <hr class="w-full border-brown" v-if="item.hr" />
          <OneButton
            :variant="variant ? variant : 'white'"
            kind="naked"
            :label="item.label"
            :iconStart="item.icon"
            :route="item.route"
            @action="onMenuItemClick(item)"
            v-else
          />
        </div>

        <!-- back button -->
        <OneButton
          variant="white"
          kind="naked"
          label="back"
          iconStart="Undo2"
          @action="menuBack()"
          v-if="selectedMenuItemParent"
        />
      </div>

      <!-- user impersonation (admins only) -->
      <MenuSignInAs
        class="mr-8"
        @cancel="showSignInAsUserField = false"
        v-if="userIsAdmin && showSignInAsUserField"
      />

      <!-- signed out: sign up / sign in area -->
      <div class="mt-4" v-if="!user.id">
        <hr class="w-full border-brown" />
        <MenuSignIn class="px-4 pb-4 pt-8" />
      </div>
    </div>
  </div>
</template>
