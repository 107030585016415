import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
import {useUserStore} from "../stores/user"
import {useAppStore} from "../stores/app"
import {auth} from "@/firebase"
import sink from "@/sink/index.js"

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {top: 0}
    }
  },
})

router.beforeEach(async (to, from) => {
  const bodyFixed = to.matched.some((record) => record.meta.bodyFixed)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const user = auth.currentUser

  // console.log({to, from})

  // save referral username to localStorage
  if (to?.redirectedFrom?.params?.referralUsername) {
    const username = to.redirectedFrom.params.referralUsername
    const referral = {username, timestamp: Date.now()}
    localStorage.setItem("@atlaseek/referral", JSON.stringify(referral))
  }

  // set body fixed
  const appStore = useAppStore()
  appStore.app.body.fixed = bodyFixed

  // set body color
  if (["DiscoverView", "ArticlesView", "ArticleView"].includes(to.name)) {
    appStore.app.body.blackBackground = true
  } else {
    appStore.app.body.blackBackground = false
  }

  // set menu visibility
  if (["DiscoverView", "ArticlesView", "ArticleView"].includes(to.name)) {
    // this is handled inside DiscoverView component
  } else {
    appStore.app.menu.visible = true
  }

  // route requires auth but user is not signed in
  if (requiresAuth && !user) {
    appStore.app.menu.popoverOpen = true
    return {name: "DiscoverView"}
  }
  // route requires auth and user is signed in
  else if (requiresAuth && user) {
    const userStore = useUserStore()
    const userRoles = userStore.userRoles
    const allowedRoles = to.meta.requiresAuthRoles
    console.log({userRoles, allowedRoles})
    const userAllowedAccess = userRoles && userRoles.some((role) => allowedRoles.includes(role))

    if (userAllowedAccess) {
      return true
    } else {
      return false
    }
  }
  // route does not require auth
  else {
    return true
  }
})

router.afterEach((to, from) => {
  const appStore = useAppStore()

  // open menu
  if (history.state?.openMenuPopover) {
    appStore.app.menu.popoverOpen = true
  }
  // close menu
  else {
    // console.log("closing menu")
    appStore.app.menu.popoverOpen = false
  }

  // log event
  sink.event("screen_view", {
    firebase_screen: to.name.replace("View", ""),
    ...(to.name == "UserProfileView" && {username: to.params.username}),
  })
})

export {router}
