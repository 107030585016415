export default {
  ViewAdmin: {
    pt: {
      root: {
        _common: "w-full h-auto md:h-screen md:bg-sand-100 p-0 md:p-4",
      },
      resizeHandle: {
        _common:
          "h-12 w-2 bg-sand-200 cursor-pointer rounded-full hover:bg-sand-300 transition-all duration-300 ease-in-out",
      },
      main: {
        _common: "w-full bg-white md:rounded-2xl md:border md:border-neutral-200 grow",
      },
    },
  },
  AdminSidebarMenu: {
    pt: {
      items: {
        _common: "flex flex-col gap-0 w-full",
      },
      itemWrap: {
        _common:
          "rounded-lg hover:bg-brown-100 w-full px-2 py-2 ease-in-out transition-all duration-300 flex flex-row justify-center @[8rem]:justify-start",
      },
      itemWrapActive: {
        _common:
          "rounded-lg w-full px-2 py-2 ease-in-out transition-all duration-300 flex flex-row justify-center @[8rem]:justify-start",
      },
      item: {
        _common:
          "flex cursor-pointer flex-row items-center justify-start w-full gap-2 text-xl font-medium text-brown-400 hover:text-brown-600 ease-in-out transition-all duration-300 relative",
      },
      itemActive: {
        _common:
          "flex cursor-pointer flex-row items-center justify-start w-full gap-2 text-xl font-medium text-brown-900",
      },
      itemIcon: {
        _common: "size-5 text-brown-400 shrink-0",
      },
      itemIconActive: {
        _common: "size-5 text-brown-800 shrink-0",
      },
      itemTitle: {
        _common: "hidden @[8rem]:inline text-brown-400 whitespace-nowrap mr-auto",
        propsStateBased: {},
      },
      itemTitleActive: {
        _common: "hidden @[8rem]:inline text-brown-800 whitespace-nowrap mr-auto",
      },
      itemBadge: {
        _common:
          "block px-2 py-0.5 rounded-full bg-brown-400 dark:bg-brown-900 text-center text-brown-100 dark:text-brown-500 text-xs font-medium",
      },
      itemBadgeActive: {
        _common:
          "block px-2 py-0.5 rounded-full bg-brown-800 text-center text-brown-100 text-xs font-medium dark:text-brown-200",
      },
    },
  },
  AdminSidebarProjectSelector: {
    pt: {
      projectActive: {
        _common:
          "h-15 flex flex-row items-center justify-between rounded-2xl @[8rem]:border @[8rem]:border-sand-200 p-0 @[8rem]:p-2  cursor-pointer transition-all duration-300 ease-in-out @[8rem]:active:bg-sand-200",
        propsStateBased: {
          showProjectsList: {
            true: "@[8rem]:bg-sand-200",
            false: "hover:bg-sand-50",
          },
        },
      },
      selectorChevrons: {
        _common:
          "pointer-events-none hidden h-6 w-6 shrink-0 text-sand-400 @[8rem]:block",
      },
      projectsList: {
        _common:
          "absolute w-full min-w-52 mt-2 flex flex-col gap-0 bg-white rounded-2xl p-1 border border-sand-200 shadow-xl shadow-sand-500/10",
      },
      projectsListItem: {
        _common:
          "flex p-1 flex-row items-center gap-2 hover:bg-sand-100 rounded-xl cursor-pointer transition-all duration-300 ease-in-out",
      },
    },
  },
  AdminSidebarUser: {
    pt: {
      userBox: {
        _common:
          "flex transition-all duration-300 ease-in-out @[8rem]:hover:bg-sand-200  cursor-pointer flex-row gap-2 rounded-full p-1 ",
      },
      userBoxSubtitle: {
        _common: "whitespace-nowrap text-xs text-brown-400",
      },
    },
  },
  Dialog: {
    pt: {
      dialog: {
        _common: "min-w-64 bg-white p-4",
      },
    },
  },
  Button: {
    pt: {
      root: {
        propsStateBased: {
          variant: {
            primaryPeach: {
              kind: {
                solid: "bg-peach-500 text-white hover:bg-peach-400",
                ghost:
                  "text-peach-500 outline-peach-500 hover:outline-peach-300 active:outline-peach-500",
              },
            },
            primaryViolet: {
              kind: {
                solid: "bg-violet-500 text-white hover:bg-violet-400",
                ghost:
                  "text-violet-500 outline-violet-500 hover:outline-violet-300 active:outline-violet-500",
              },
            },
            secondary: {
              kind: {
                solid: "bg-sand-200 text-brown-600 hover:bg-sand-300",
              },
            },
            disabled: {
              kind: {
                solid: "bg-neutral-200 text-neutral-400",
              },
            },
            white: {
              kind: {
                solid:
                  "bg-white hover:bg-neutral-200 active:bg-neutral-400 text-brown-800",
              },
            },
          },
        },
      },
    },
  },
  Input: {
    pt: {
      container: {
        propsStateBased: {
          // focused: {
          //   true: {
          //     status: {
          //       false: "border-brown-500 shadow-brown-500",
          //     },
          //   },
          //   false: {
          //     status: {
          //       false: "border-neutral-700 shadow-neutral-700",
          //     },
          //   },
          // },
          focused: {
            true: {
              _common: "shadow-[0px_0px_0px_1px]",
              status: {
                false: "border-brown-400 shadow-brown-400",
                success: "border-brown-400 shadow-brown-400",
                error: "border-critical-500 shadow-critical-500",
                progress: "border-brown-400 shadow-brown-400",
              },
            },
            false: {
              status: {
                false: "border-brown-300",
                success: "border-brown-300",
                error: "border-critical-500",
                progress: "border-brown-300",
              },
            },
          },
        },
      },
      labelOuter: {
        _common: "inline-block text-base font-bold text-brown-500 font-element",
      },
      labelInner: {
        _common: "left-1.5 text-brown-600 text-left text-sm font-bold pt-0.5",
      },
      placeholder: {
        _common:
          "flex flex-row items-center justify-start absolute inset-0 font-normal text-brown-300 pointer-events-none font-element whitespace-nowrap",
      },
      // inputField: {
      //   // _common: "flex flex-row items-center justify-start absolute inset-0 text-brown-800 text-left",
      // },
    },
  },
  Select: {
    pt: {
      selectWrapper: {
        _common:
          "flex min-h-11 w-full cursor-pointer flex-row items-center gap-2 rounded-xl p-2 transition-all duration-300 ease-in-out bg-sand-200 hover:bg-sand-200",
      },
      placeholder: {
        _common: "text-brown-500",
      },
      chevron: {
        _common: "pointer-events-none shrink-0 text-brown-200",
      },
      optionsContainer: {
        _common:
          "absolute left-0 right-0 top-full z-50 mt-1 flex max-h-64 flex-col gap-0 rounded-2xl border border-neutral-200 bg-white p-2",
      },
      option: {
        _common:
          "flex w-full cursor-pointer flex-row items-center justify-between rounded-lg p-2 transition-all duration-300 ease-in-out hover:bg-neutral-200",
      },
      optionMultiSelected: {
        _common:
          "flex w-full cursor-pointer flex-row items-center justify-between rounded-lg p-2 transition-all duration-300 ease-in-out hover:bg-neutral-200 bg-neutral-150",
      },
      optionLabel: {
        _common: "pointer-events-none select-none text-neutral-900",
      },
      optionCheckIcon: {
        _common: "pointer-events-none text-neutral-900",
      },

      labelOuter: {
        _common:
          "inline-block text-base font-bold text-neutral-600 font-element",
      },
      caption: {
        _common: "mt-1 text-sm text-neutral-400",
      },
      modeViewValue: {
        _common: "text-neutral-900 ",
      },
    },
  },
  Radio: {
    pt: {
      option: {
        propsStateBased: {
          filled: {
            true: "bg-brown-100",
            false: "outline outline-brown-300",
          },
          disabled: {
            true: "cursor-not-allowed",
            false: {
              _common: "cursor-pointer",
              filled: {
                true: "hover:bg-brown-200",
                false: "hover:outline-brown-400",
              },
            },
          },
        },
      },
      optionSelected: {
        propsStateBased: {
          filled: {
            true: "outline outline-2 outline-brown-600 bg-brown-100",
            false: "outline outline-2 outline-brown-600",
          },
        },
      },
      optionLabel: {
        _common: "font-bold",
        propsStateBased: {
          disabled: {
            true: "text-brown-200",
            false: "text-brown-700",
          },
        },
      },
      labelOuter: {
        _common: "inline-block text-base font-bold text-brown-500 font-element",
      },
    },
  },
  Switch: {
    pt: {
      switch: {
        _common:
          "bg-sand-200 cursor-pointer flex flex-row items-center relative",
      },
      switchToggle: {
        propsStateBased: {
          modelValue: {
            false: {
              _common: "bg-brown-400",
            },
            true: {
              _common: "bg-brown-800",
            },
          },
        },
      },
      labelEnd: {
        _common: "inline-block text-base font-bold text-brown-600 font-element",
      },
    },
  },
  Chip: {
    pt: {
      root: {
        propsStateBased: {
          variant: {
            primaryPeach: {
              kind: {
                solid: "bg-peach-500 text-white",
              },
            },
            primaryViolet: {
              kind: {
                solid: "bg-violet-500 text-white",
              },
            },
            secondary: {
              kind: {
                solid: "bg-sand-200 text-brown-700",
              },
            },
          },
        },
      },
    },
  },
  RatingStars: {
    pt: {
      star: {
        _common: "text-violet-500",
      },
      labelOuter: {
        _common: "inline-block text-base font-bold text-brown-500 font-element",
      },
    },
  },
}
