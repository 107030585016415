<script setup>
import {auth, functions} from "@/firebase"
import {httpsCallable} from "firebase/functions"
import {signInWithCustomToken} from "@firebase/auth"
import {OButton, OInput} from "@jirizavadil/framework.one/ui"
import {ref} from "vue"
import {useUserStore} from "../../stores/user"

const emit = defineEmits(["cancel"])

// stores
const userStore = useUserStore()

const signInAsUsername = ref("")
const signInAsFn = httpsCallable(functions, "calls-signInAs")
const signInAsProgress = ref(false)

async function signInAs() {
  if (!signInAsUsername.value) {
    return
  }

  signInAsProgress.value = true
  const response = await signInAsFn({
    username: signInAsUsername.value,
  })

  const token = response.data.token

  console.log(token)
  if (token) {
    await userStore.unbind()
    await signInWithCustomToken(auth, token)
    emit("cancel")
  }

  signInAsProgress.value = false
}
</script>

<template>
  <div class="">
    <div class="border border-brown-200 bg-sand-100 rounded-xl w-full p-4 m-4">
      <span>sign in as</span>
      <OInput v-model="signInAsUsername" label="@username" @enter="signInAs" />

      <div class="mt-4 flex flex-row gap-4">
        <OButton kind="naked" label="cancel" @action="$emit('cancel')" />
        <OButton label="sign in" :progress="signInAsProgress" @action="signInAs" />
      </div>
    </div>
  </div>
</template>
