import DiscoverView from "../views/DiscoverView.vue"

export const routes = [
  {
    path: "/",
    name: "DiscoverView",
    component: DiscoverView,
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
    children: [
      {
        path: "discover",
        name: "ArticlesView",
        component: () => import("../views/ArticlesView.vue"),
        meta: {
          bodyFixed: false,
          requiresAuth: false,
        },
      },
      {
        path: "discover/:countrySlug",
        name: "ArticlesCountryView",
        component: () => import("../views/ArticlesView.vue"),
        meta: {
          bodyFixed: false,
          requiresAuth: false,
        },
      },
      {
        path: "discover/:countrySlug/:locationSlug",
        name: "ArticlesCountryLocationView",
        component: () => import("../views/ArticlesView.vue"),
        meta: {
          bodyFixed: false,
          requiresAuth: false,
        },
        children: [
          {
            path: ":articleSlug",
            name: "ArticleView",
            component: () => import("../views/ArticleView.vue"),
            meta: {
              bodyFixed: false,
              requiresAuth: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/feed",
    name: "FeedView",
    component: () => import("../views/FeedView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/search",
    name: "SearchView",
    component: () => import("../views/SearchView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/chat",
    name: "ChatView",
    component: () => import("../views/ChatView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/manager/articles",
    name: "ArticleManagerView",
    component: () => import("../views/ArticleManagerView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: true,
      requiresAuthRoles: ["admin", "exploreCreator"],
    },
    children: [
      {
        path: ":articleId",
        name: "ArticleManagerArticleView",
        component: () => import("../views/ArticleManagerArticleView.vue"),
        meta: {
          bodyFixed: false,
          requiresAuth: true,
          requiresAuthRoles: ["admin", "exploreCreator"],
        },
      },
    ],
  },
  {
    path: "/roadmap",
    name: "RoadmapView",
    component: () => import("../views/RoadmapView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/faq",
    name: "FaqView",
    component: () => import("../views/FaqView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/terms",
    name: "TermsView",
    component: () => import("../views/TermsView.vue"),
    meta: {
      contentKey: "terms",
    },
  },
  {
    path: "/press-kit",
    name: "PressKitView",
    component: () => import("../views/PressKitView.vue"),
  },
  {
    path: "/plans",
    name: "PlansView",
    component: () => import("../views/PlansView.vue"),
  },
  {
    path: "/team",
    name: "TeamView",
    component: () => import("../views/TeamView.vue"),
  },
  {
    path: "/apply-as-creator",
    name: "ApplyAsCreatorView",
    component: () => import("../views/ApplyAsCreatorView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
    children: [
      {
        path: "success",
        name: "ApplyAsCreatorSuccessView",
        component: () => import("../views/ApplyAsCreatorSuccessView.vue"),
      },
    ],
  },
  {
    path: "/password-recovery",
    name: "PasswordRecoveryView",
    component: () => import("../views/PasswordRecoveryView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/lifetime",
    name: "LifetimeView",
    component: () => import("../views/LifetimeView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    path: "/settings",
    name: "SettingsView",
    component: () => import("../views/SettingsView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: true,
      requiresAuthRoles: ["admin", "exploreCreator", "exploreExplorer"],
    },
    children: [
      {
        path: "plan",
        name: "SettingsPlanView",
        component: () => import("../views/SettingsPlanView.vue"),
        meta: {
          bodyFixed: false,
          requiresAuth: true,
          requiresAuthRoles: ["admin", "exploreCreator", "exploreExplorer"],
        },
      },
      {
        path: "admin",
        name: "SettingsAdminView",
        component: () => import("../views/SettingsAdminView.vue"),
        meta: {
          bodyFixed: false,
          requiresAuth: true,
          requiresAuthRoles: ["admin"],
        },
      },
    ],
  },
  // blog
  {
    path: "/blog",
    name: "BlogView",
    component: () => import("../views/BlogView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
      contentKey: "blog",
    },
    children: [
      {
        path: ":slug",
        name: "BlogPostView",
        component: () => import("../views/BlogView.vue"),
        meta: {
          bodyFixed: false,
          requiresAuth: false,
          contentKey: "blog-post",
        },
      },
    ],
  },
  {
    // @ is mandatory
    path: "/@:username([a-zA-Z0-9_\\.]{3,30})",
    name: "UserProfileView",
    component: () => import("../views/UserProfileView.vue"),
    meta: {
      bodyFixed: false,
      requiresAuth: false,
    },
  },
  {
    // @ is optional
    path: "/join/:referralUsername([a-zA-Z0-9_\\.]{3,30})",
    alias: "/join/@:referralUsername([a-zA-Z0-9_\\.]{3,30})",
    // redirect to user profile & pass referralUsername as param
    redirect: (to) => {
      const {params} = to
      params.username = params.referralUsername
      return {name: "UserProfileView", params}
    },
  },
  {path: "", redirect: {name: "DiscoverView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "DiscoverView"}},
]

// atlaseek.com/discover/france/eiffel-tower/how-to-visit-eiffel-tower

// Socotra
// Socotra airport
// Socotra Island Nature Sanctuary
