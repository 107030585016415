<script setup>
import LogoAtlaseekInverted from "../assets/img/logo/atlaseek - vector rgb - primary colored inverted.svg"
import {OIcon} from "@jirizavadil/framework.one/ui"
import LayoutContainer from "./layout/LayoutContainer.vue"
import sink from "@/sink/index.js"
import {firestore} from "@/firebase"
import {collection, getDocs, query, limit, where} from "firebase/firestore"
import sortBy from "lodash/sortBy"
import {ref, onMounted, computed} from "vue"
import BaseImage from "@atlaseek/common/components/base/BaseImage.vue"
import LogoGoogleForStartupsCloudProgram from "../assets/img/logo-google-for-startups-cloud-program.svg"
import LogoMicrosoftForStartups from "../assets/img/logo-microsoft-for-startups.svg"
import LogoUNTourism from "../assets/img/logo-un-tourism.svg"

const creators = ref([])
const creatorsLoaded = ref(false)

const creatorsSorted = computed(() => {
  return sortBy(creators.value, ["meta.created"], ["desc"])
})

async function getCreators() {
  const userProfilesRef = collection(firestore, "userProfiles")
  const q = query(
    userProfilesRef,
    where("roles.creator", "==", true),
    where("visibility.publicListing", "==", true),
    limit(50)
  )
  const userProfilesSnapshot = await getDocs(q)
  userProfilesSnapshot.docs.map((doc) => creators.value.push(doc.data()))
  creatorsLoaded.value = true
}

function onClickExplore() {
  sink.event("clicked_view_discover_landing_explore")
  window.scrollTo(0, document.body.scrollHeight)
}

function onClickApplyAsCreator() {
  sink.event("clicked_view_discover_landing_join_as_creator")
}

function onClickCreator(creator) {
  sink.event("clicked_view_discover_landing_creator", {username: creator.username})
}

onMounted(() => {
  getCreators()
})
</script>

<template>
  <LayoutContainer>
    <div class="text-white">
      <!-- logo -->
      <LogoAtlaseekInverted class="h-auto w-40 pt-8" />

      <!-- title -->
      <h2 class="mt-32 text-4xl md:text-6xl lg:text-7xl">
        Explore the world<br />like your favorite creators
      </h2>
      <p class="mt-4 max-w-md text-xl opacity-70 md:max-w-2xl md:text-3xl md:leading-10">
        The easiest way to support multiple creators and get exclusive content. Learn about our
        planet through inspiring story tellers.
      </p>

      <!-- CTA -->
      <div class="mt-8 flex flex-row items-center justify-start gap-4">
        <div
          class="inline-flex cursor-pointer flex-row items-center justify-center gap-2 rounded-full bg-peach-500 px-4 py-3 transition-all duration-300 ease-in-out hover:bg-peach-300 md:px-6 md:py-4"
          @click="onClickExplore()"
        >
          <span class="text-2xl md:text-3xl">explore</span>
          <OIcon lucide="ArrowRight" class="h-7 w-7 md:h-9 md:w-9" />
        </div>
      </div>

      <!-- features -->
      <div class="mt-32 flex max-w-xl flex-col gap-4 md:max-w-none md:flex-row md:gap-10">
        <div class="">
          <h3 class="text-xl font-bold md:text-2xl">unique subscription model</h3>
          <p class="opacity-60 md:text-xl">
            don't choose just 1 or 2 creators you can support, 50% of subscription goes to all
            creators based on your watch and read time
          </p>
        </div>

        <div class="">
          <h3 class="text-xl font-bold md:text-2xl">get exclusive content</h3>
          <p class="opacity-60 md:text-xl">
            personal travel stories, guides, trip reports, early youtube videos - get a new
            perspective on our beautiful planet, completely ads free
          </p>
        </div>

        <div class="">
          <h3 class="text-xl font-bold md:max-w-xs md:text-2xl">
            connect with creators and travelers
          </h3>
          <p class="opacity-60 md:text-xl">
            inspiration doesn't only come from great content, but also from the community - connect
            1to1 with your favorite creator or chat with fellow travelers
          </p>
        </div>
      </div>

      <!-- creators -->
      <div class="">
        <h3 class="mt-20 text-2xl font-bold md:text-3xl">creators</h3>

        <BaseLoading class="" v-if="!creatorsLoaded" />
        <div class="mt-4 flex flex-row flex-wrap gap-y-2 md:gap-2" v-if="creatorsLoaded">
          <RouterLink
            :to="{name: 'UserProfileView', params: {username: creator.username}}"
            v-for="creator in creatorsSorted"
            :key="creator.username"
            class="flex w-40 cursor-pointer flex-col gap-2 rounded-xl p-2 transition-all duration-300 ease-in-out hover:bg-black hover:bg-opacity-40 md:w-44"
            @click="onClickCreator(creator)"
          >
            <BaseImage
              class="aspect-[1/1] h-12 w-12 rounded-full object-cover"
              :image="creator.profilePhoto"
              breakpoints="def:240|xs:240|sm:240|md:240|lg:240|xl:240"
              v-if="creator.profilePhoto"
            />
            <!-- old implementation, -> remove when all images reuploaded -->
            <img
              class="h-12 w-12 rounded-full"
              :src="creator.photoURL"
              v-else-if="creator.photoURL"
            />
            <div class="h-12 w-12 rounded-full bg-sand-300" v-else />

            <div class="flex flex-col gap-0">
              <span class="inline-block overflow-hidden text-ellipsis"
                >@{{ creator.username }}</span
              >
              <span class="opacity-60">{{ creator.stats.countries }} countries</span>
            </div>
          </RouterLink>

          <RouterLink
            :to="{name: 'ApplyAsCreatorView'}"
            class="flex w-40 items-center justify-center rounded-xl border-2 border-dashed border-opacity-30 py-5 text-center text-base transition-all duration-300 ease-in-out hover:bg-black hover:bg-opacity-40 md:w-44"
            @click="onClickApplyAsCreator()"
            >apply<br />as creator</RouterLink
          >
        </div>
      </div>

      <!-- partners -->
      <div class="">
        <h3 class="mt-20 text-xs font-bold uppercase tracking-[0.05rem] opacity-50">
          our amazing partners
        </h3>
        <div
          class="mt-6 flex flex-col items-start justify-start gap-6 md:mt-6 md:flex-row md:items-center md:gap-12"
        >
          <LogoUNTourism class="h-10 w-auto opacity-50" />
          <LogoGoogleForStartupsCloudProgram class="h-6 w-auto opacity-50" />
          <LogoMicrosoftForStartups class="h-6 w-auto opacity-50" />
        </div>
      </div>

      <!-- gap -->
      <div class="h-screen w-full"></div>
    </div>
  </LayoutContainer>
</template>
