<script setup>
import {nextTick, ref, computed, watch, setBlockTracking} from "vue"
import {auth, functions} from "@/firebase"
import {
  GoogleAuthProvider,
  signInWithPopup,
  // signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth"
import {OIcon, OButton} from "@jirizavadil/framework.one/ui"
import IconGoogle from "@/assets/img/icons/icon-google.svg"
import {useRouter} from "vue-router"
// import isMobile from "ismobilejs"
import {useAppStore} from "../../stores/app"
import {useUserStore} from "../../stores/user"
import {storeToRefs} from "pinia"
import {httpsCallable} from "firebase/functions"
import sink from "@/sink/index.js"
import {writeBatch} from "firebase/firestore"

const props = defineProps({
  // used for events
  parentView: {
    type: String,
    default: "",
  },
  googleLabel: {
    type: String,
    default: "",
  },
  googleColor: {
    type: String,
    default: "brown",
  },
  emailLabel: {
    type: String,
    default: "or use email",
  },
})

const emit = defineEmits(["signin-start", "signup-start", "signed-in", "signed-up"])

const usersCheckFn = httpsCallable(functions, "calls-usersCheck")
const router = useRouter()
const appStore = useAppStore()
const userStore = useUserStore()
const {app} = storeToRefs(appStore)

// auth.languageCode = "en"
auth.useDeviceLanguage()

const credentials = ref({
  email: "",
  password: "",
})
const showCredentialsForm = ref(false)
const showCredentialsPasswordField = ref(false)
const credentialsFormSubmitProgress = ref(false)
const credentialsFormEmailExists = ref(false)
const credentialsFormStage = ref("email")
const credentialsFormSubmitLabel = ref("")
const errorMessage = ref("")
const usernameField = ref(null)
const passwordField = ref(null)
const newsletterField = ref(true)
const providerGoogleEnabled = computed(() => {
  // google login in embedded browsers (eg. Instagram) is not supported (security reasons) and there is no way around it
  // seems to work in X and YouTube
  console.log("UA:", app.value.userAgent)

  // so far the best way to detect webview - on ios, safari only inlcudes 'safari' in user agent string when it is not in webview
  return ["Safari", "Mobile Safari", "Chrome", "Firefox"].includes(app.value.userAgent.browser.name)
  // return false // !["Instagram", "TikTok", "Facebook", "LinkedIn"].includes(app.value.userAgent.browser.name)
})

async function signInWithGoogle() {
  const provider = new GoogleAuthProvider()

  emit("signin-start", {provider: "google"})
  sink.event(`clicked_${props.parentView}_auth_google`)

  // redirect will probably only work only after it runs on main domain and the domain is same as FIREBASE_AUTH_DOMAIN (eg. app.atlaseek.com)
  // if (isMobile(window.navigator).any) {
  // signInWithRedirect(auth, provider)
  // } else {
  const response = await signInWithPopup(auth, provider).catch((e) => {
    sink.event(`${props.parentView}_auth_error`, {
      code: e.code,
      event: "signIn",
      provider: "google",
    })
    console.error(e.code)
  })
  // console.log({response})
  console.log("emitting signed-in google")
  emit("signed-in", {provider: "google"})
  // }
}

function onClickUseEmail() {
  showCredentialsForm.value = true
  sink.event(`clicked_${props.parentView}_auth_use_email`)
}

function onInputBlur(field) {
  const value = field === "email" ? credentials.value.email : "***"
  sink.event(`${props.parentView}_auth_input_${field}`, {value})
}

async function onClickSubmit() {
  // console.log("onClickSubmit()")
  if (!credentials.value.email) {
    errorMessage.value = "email is required"
    return
  }
  // user inputs email
  if (credentialsFormStage.value === "email") {
    await checkEmail()
  }

  // user inputs password
  else if (credentialsFormStage.value === "password") {
    await signInOrSignUp()
  }
}

async function checkEmail() {
  credentialsFormSubmitProgress.value = true
  const response = await usersCheckFn({action: "exists", email: credentials.value.email})
  // console.log("exists:", response.data.exists)
  credentialsFormEmailExists.value = response.data.exists

  // email exists -> sign in
  if (credentialsFormEmailExists.value) {
    credentialsFormSubmitLabel.value = "sign in"
  }
  // email does not exist -> sign up
  else {
    credentialsFormSubmitLabel.value = "sign up"
  }

  showCredentialsPasswordField.value = true
  credentialsFormStage.value = "password"

  await nextTick()
  passwordField.value.focus()
  credentialsFormSubmitProgress.value = false
  return
}

async function signInOrSignUp() {
  // console.log("signInOrSignUp()")
  errorMessage.value = ""

  // email exists -> sign in
  if (credentialsFormEmailExists.value) {
    await signIn()
  }
  // email does not exist -> sign up
  else {
    await signUp()
  }
}

async function signIn() {
  emit("signin-start", {provider: "email"})
  // console.log("signIn()")
  credentialsFormSubmitProgress.value = true
  const email = credentials.value.email
  const password = credentials.value.password
  const response = await signInWithEmailAndPassword(auth, email, password).catch((e) => {
    sink.event(`${props.parentView}_auth_error`, {
      code: e.code,
      event: "signIn",
      provider: "email",
    })
    if (e.code == "auth/user-not-found") {
      console.log("user not found, signing up")
      return
    } else {
      console.error(e.code)
      errorMessage.value = e.code
      return
    }
  })
  // console.log({response})
  if (response) {
    // close popover
    app.value.menu.popoverOpen = false
    // console.log("emitting signed-in")
    emit("signed-in", {provider: "email"})
  }
  credentialsFormSubmitProgress.value = false
}

// database user entry is created by events-beforeAuthUserCreated.fn
async function signUp() {
  emit("signup-start", {provider: "email"})
  console.log("signUp()")
  credentialsFormSubmitProgress.value = true
  const email = credentials.value.email
  const password = credentials.value.password
  const response = await createUserWithEmailAndPassword(auth, email, password).catch((e) => {
    console.error(e.code)
    errorMessage.value = e.code
    sink.event(`${props.parentView}_auth_error`, {
      code: e.code,
      event: "signUp",
      provider: "email",
    })
  })

  // console.log({response})

  const userUpdatesData = {}

  // check if user was referred
  const lsReferral = localStorage.getItem("@atlaseek/referral")
  const referral = lsReferral ? JSON.parse(lsReferral) : false

  if (referral) {
    const {username, timestamp} = referral
    // check if timestamp is within last 24 hours
    const now = new Date().getTime()
    const diff = now - timestamp
    const diffHours = diff / (1000 * 60 * 60)
    if (diffHours < 24) {
      // save referral
      userUpdatesData.referredBy = username
    }
    // delete referral from local storage
    localStorage.removeItem("@atlaseek/referral")
  }

  // save newsletter preference
  if (newsletterField.value) {
    userUpdatesData.newsletter = true
  }

  if (Object.keys(userUpdatesData).length > 1) {
    const userId = response.user.uid
    await userStore.update({userId, data: userUpdatesData})
  }

  emit("signed-up", {provider: "email"})

  // console.log("pushing to signup")
  // close popover
  credentialsFormSubmitProgress.value = false
  app.value.menu.popoverOpen = false
  // router.push({
  //   name: "SignUpView",
  // })
}

watch(
  providerGoogleEnabled,
  (newValue) => {
    if (!newValue) {
      console.log("providerGoogleEnabled changed", newValue)
      showCredentialsForm.value = true
    }
  },
  {immediate: true}
)
</script>

<template>
  <div class="inline-flex flex-col gap-4 text-center">
    <!-- providers -->
    <div class="mt-4 flex flex-center">
      <!-- google -->
      <div
        class="flex cursor-pointer rounded-full bg-brown-400 transition-all duration-300 ease-in-out flex-center hover:bg-brown"
        :class="[
          {'gap-3 px-5 py-3': googleLabel},
          {'h-10 w-10': !googleLabel},
          {'bg-brown-400 hover:bg-brown': googleColor == 'brown'},
          {'bg-peach-500 hover:bg-peach-300': googleColor == 'peach'},
        ]"
        @click="signInWithGoogle()"
        v-if="providerGoogleEnabled"
      >
        <IconGoogle class="h-4 w-4" /><span class="text-xl text-white" v-if="googleLabel">{{
          googleLabel
        }}</span>
      </div>
    </div>

    <span
      class="mt-4 cursor-pointer text-brown-400 underline"
      @click="onClickUseEmail()"
      v-if="!showCredentialsForm"
      >{{ emailLabel }}</span
    >

    <!-- email & password -->
    <form
      class="mt-6 flex flex-col gap-2"
      @submit.prevent="onClickSubmit()"
      v-if="showCredentialsForm"
    >
      <span class="pb-3 text-xl" v-if="!providerGoogleEnabled">sign up with e-mail</span>
      <input
        ref="usernameField"
        class="rounded-full border-none bg-brown-500 px-4 py-2 text-white outline-none placeholder:text-brown-400 focus:ring-0"
        type="text"
        placeholder="peter@parker.com"
        autocomplete="username"
        @keyup="errorMessage = ''"
        @blur="onInputBlur('username')"
        v-model="credentials.email"
      />
      <input
        ref="passwordField"
        class="rounded-full border-none bg-brown-500 px-4 py-2 text-white outline-none placeholder:text-brown-400 focus:ring-0"
        :type="credentialsFormEmailExists ? 'password' : 'text'"
        :placeholder="credentialsFormEmailExists ? 'password' : 'create password'"
        :autocomplete="credentialsFormEmailExists ? 'current-password' : 'new-password'"
        v-model="credentials.password"
        @blur="onInputBlur('password')"
        v-if="showCredentialsPasswordField"
      />
      <span class="text-red-800" v-if="errorMessage">{{ errorMessage }}</span>

      <div
        class="mt-2 flex flex-row items-center justify-center gap-3"
        v-if="showCredentialsPasswordField && !credentialsFormEmailExists"
      >
        <input
          v-model="newsletterField"
          name="newsletter"
          type="checkbox"
          class="h-6 w-6 rounded-lg border-brown-200 bg-transparent text-peach-500 focus:ring-0"
        />
        <span class="text-brown-400">get monthly news</span>
      </div>

      <div class="mt-4 flex flex-center">
        <!-- sign in/up button -->
        <OButton
          status="primaryPeach"
          :label="credentialsFormSubmitLabel"
          :icon="credentialsFormStage == 'email'"
          :iconLucide="credentialsFormStage == 'email' ? 'ArrowRight' : false"
          kind="solid"
          :progress="credentialsFormSubmitProgress"
        />
      </div>
      <!-- terms -->
      <p
        class="mt-2 text-sm text-brown-400"
        v-if="showCredentialsPasswordField && !credentialsFormEmailExists"
      >
        by signing up, you accept
        <a class="underline" href="https://atlaseek.com/terms" target="_blank">terms</a>
      </p>
      <!-- forgot password -->
      <p
        class="mt-2 text-sm text-brown-400 underline"
        v-if="showCredentialsPasswordField && credentialsFormEmailExists"
      >
        <RouterLink :to="{name: 'PasswordRecoveryView', query: {email: credentials.email}}"
          >forgot password?</RouterLink
        >
      </p>
    </form>
  </div>
</template>
