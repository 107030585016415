<script setup>
import {ref, computed} from "vue"
import {useUserStore} from "../stores/user"
import {storeToRefs} from "pinia"
import {OButton} from "@jirizavadil/framework.one/ui"
import {useRoute, useRouter} from "vue-router"

const emit = defineEmits(["close"])

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()

const {user} = storeToRefs(userStore)

const oldPlan = ref(route.query.old_plan)
const currentPlan = computed(() => user.value.plan)
const progress = computed(() => oldPlan.value === currentPlan.value)

function onClickStartExploring() {
  router.replace({ query: {} });
  emit("close")
}
</script>

<template>
  <div class="flex justify-center p-4">
    <!-- waiting for plan upgrade to process -->
    <div class="" v-if="progress">
      <span class="">Upgrading to first class...</span>
      <BaseLoading class="mt-4" />
    </div>

    <!-- plan upgrade processed -->
    <div class="text-center flex flex-col gap-2 justify-center items-center" v-if="!progress">
      <span class="block text-3xl">Yeehaw! 🤠</span>
      <span class="block mt-4">you're now on {{ user.plan }} plan</span>
      <OButton
        class="mt-4"
        status="primaryPeach"
        label="start exploring"
        @click="onClickStartExploring()"
      />
    </div>
  </div>
</template>
