<script setup>
import {ref, computed, useAttrs} from "vue"

const props = defineProps({
  image: {
    type: Object, // imageId, contentType
  },

  // breakpoint overrides = max image width at widest state of given breakpoint
  // - "auto" - use tailwind breakpoints
  // - "def:400|sm:500|md:600" -> image will be nearest larger of 400px wide on default breakpoint, 500px on sm, 600px on md. lg+ stays default
  // - "lg:1080|xl:1080" -> image will never be wider than 1080px, regardless of screen size. Useful for images that are not full-width.
  breakpoints: {
    type: String,
  },
})

// available image widths, dprs & types (as configured in events-onUserUpload.fn)
// order matters for proper srcset generation
const config = {
  breakpoints: [240, 320, 480, 640, 720, 1080, 1280, 1440, 1920, 2560],
  devicePixelRatios: [1, 2, 3],
  imageTypes: [
    {mime: "image/webp", extension: "webp"},
    {mime: "image/jpeg", extension: "jpg"},
  ],
}

// breakpoint mapping to default loaded image width
const breakpointMap = [
  {name: "xl", bpWidth: 1200, imgWidth: 2560},
  {name: "lg", bpWidth: 992, imgWidth: 1280},
  {name: "md", bpWidth: 768, imgWidth: 1080},
  {name: "sm", bpWidth: 576, imgWidth: 1080},
  {name: "xs", bpWidth: 375, imgWidth: 640},
  {name: "def", bpWidth: 0, imgWidth: 480},
]

const urlCDN =
  import.meta.env.VITE_ENVIRONMENT === "local_dev" && import.meta.env.VITE_USE_EMULATOR === "false"
    ? import.meta.env.VITE_URL_CDN_PRODUCTION
    : import.meta.env.VITE_URL_CDN

const urlImages = `${urlCDN}/images`
const attrs = useAttrs()
const classes = attrs.class
const srcFallbackImage = ref(null)

const breakpointOverrides = computed(() => {
  if (!props.breakpoints || props.breakpoints == "auto") {
    return {}
  }
  const breakpoints = props.breakpoints.split("|")
  const map = {}
  breakpoints.forEach((breakpoint) => {
    const [name, width] = breakpoint.split(":")
    map[name] = parseInt(width)
  })
  return map
})

const sources = computed(() => {
  if (!breakpointMap) {
    return []
  }
  const sources = []
  // console.time("BaseImage.vue:sources")

  breakpointMap.forEach((tailwindBreakpoint) => {
    // if (Object.hasOwn(breakpointMap.value, tailwindBreakpoint.name)) {
    const breakpoint = tailwindBreakpoint.name
    let imageWidth = tailwindBreakpoint.imgWidth
    const breakpointOverride = breakpointOverrides.value[tailwindBreakpoint.name]

    // breakpoint has image width override
    if (breakpointOverride) {
      // lookup nearest larger available image width
      imageWidth = config.breakpoints.find((b) => b >= breakpointOverride)
    }

    // loop image types
    config.imageTypes.forEach((imageType) => {
      const minWidth = breakpoint == "def" ? 0 : tailwindBreakpoint.bpWidth
      const source = {
        type: imageType.mime,
        media: `(min-width: ${minWidth}px)`,
      }
      let srcset = []

      // loop device pixel ratios
      config.devicePixelRatios.forEach((devicePixelRatio) => {
        const extension = imageType.extension
        const src = `${urlImages}/${props.image.imageId}___w_${imageWidth}__x_${devicePixelRatio}.${extension}`
        srcset.push(`${src} ${devicePixelRatio}x`)

        // if this is the largest jpeg@1x, use it as fallback
        if (imageType.mime == "image/jpeg" && devicePixelRatio == 1 && !srcFallbackImage.value) {
          srcFallbackImage.value = src
        }
      })
      source.srcset = srcset.join(", ")
      sources.push(source)
    })
    // }
  })

  // console.timeEnd("BaseImage.vue:sources")

  return sources
})


defineOptions({
  inheritAttrs: false // prevents from applying classes to <picture>
})
</script>

<template>
  <picture>
    <source
      v-for="(source, index) in sources"
      :key="index"
      :srcset="source.srcset"
      :type="source.type"
      :media="source.media"
    />

    <!-- fallback: the largest jpeg@1x of above -->
    <img :class="classes" :src="srcFallbackImage" type="image/jpeg" />
  </picture>
</template>
