import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import UAParser from "ua-parser-js"
const uaParser = new UAParser()

export const useAppStore = defineStore("app", () => {
  // state
  const app = ref({
    scrollY: 0,
    landingPage: {
      seen: false,
    },
    body: {
      fixed: false,
      blackBackground: true,
    },
    notification: {
      active: false,
      display: "toast", // toast, modal
      type: "error",
      title: "hello",
      message: "there",
      delay: 5000,
    },
    universalDrawer: {
      el: null,
      scrollY: 0,
      scrollDirection: null,
      scrolling: false,
      width: 0,
      height: 0,
    },
    universalModal: {
      active: false,
    },
    menu: {
      visible: false,
      popoverOpen: false,
    },
    mapView: {
      width: null,
      height: null,
      locationSelected: null,
      locationHighLighted: null,
      showZoomControls: false,
    },
    article: {
      opened: false,
      header: {
        height: null,
        heightInitial: 400,
        heightMinimized: 75,
        minimized: false,
      },
      youtubeVideoPlayer: {
        videoId: null,
        currentState: null,
        videoDuration: null,
        videoCurrentTime: null,
        eventInterval: null,
      },
    },
    viewport: {
      width: null,
      height: null,
      size: null, // approximated breakpoint
      currentBreakpoint: null,
      breakpoints: {
        // has to be same as tailwind.config.cjs
        xs: 375,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    showCreatorWishlist: false,
    creatorWishlistPinOpened: false,
    pwa: {
      installable: "standalone" in window.navigator,
      // installed: false, // iOS can't detect
      running: window.navigator.standalone === true,
    },
    userAgent: uaParser.getResult(),
  })

  // actions
  function setViewportWidth(args) {
    const width = args
    app.value.viewport.width = width

    // breakpoint
    let cb = "xl"
    if (width < app.value.viewport.breakpoints.xl) cb = "lg"
    if (width < app.value.viewport.breakpoints.lg) cb = "md"
    if (width < app.value.viewport.breakpoints.md) cb = "sm"
    if (width < app.value.viewport.breakpoints.sm) cb = "xs"
    if (width < app.value.viewport.breakpoints.xs) cb = "_def"
    app.value.viewport.currentBreakpoint = cb

    // viewport size
    let vs = null
    if (["_def", "xs"].includes(cb)) vs = "mobile"
    if (["sm", "md"].includes(cb)) vs = "tablet"
    if (["lg", "xl"].includes(cb)) vs = "desktop"
    app.value.viewport.size = vs
  }

  function setViewportHeight(args) {
    const height = args
    app.value.viewport.height = height
  }

  function appNotification({
    active = true,
    display = "toast",
    type = "info",
    title,
    message,
    delay = 5000,
  }) {
    app.value.notification.active = active
    app.value.notification.display = display
    app.value.notification.type = type
    app.value.notification.title = title
    app.value.notification.message = message
    app.value.notification.delay = delay

    setTimeout(() => {
      app.value.notification.active = false
    }, delay)
  }

  return {
    // state
    app,

    // actions
    setViewportWidth,
    setViewportHeight,
    appNotification,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
