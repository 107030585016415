<script setup>
// docs reference: https://cesium.com/learn/ion-sdk/ref-doc/

// examples:
// custom geocoder: https://sandcastle.cesium.com/index.html?src=Custom%20Geocoder.html
// points: https://sandcastle.cesium.com/index.html?src=Points.html
// points clustering: https://sandcastle.cesium.com/index.html?src=Clustering.html
// billboards: https://sandcastle.cesium.com/index.html?src=Billboards.html
// geojson: https://sandcastle.cesium.com/index.html?src=GeoJSON%20and%20TopoJSON.html
// photorealistic 3D tiles (google): https://sandcastle.cesium.com/index.html?src=Google%20Photorealistic%203D%20Tiles.html
// picking: https://sandcastle.cesium.com/index.html?src=Picking.html
// atmosphere: https://sandcastle.cesium.com/?src=Atmosphere.html
// imagery color adjustment: https://sandcastle.cesium.com/?src=Imagery%20Adjustment.html
// resolution scaling: https://sandcastle.cesium.com/?src=Resolution%20Scaling.html
// Google Maps Autocomplete & Places examples: https://developers.google.com/maps/documentation/tile/use-renderer

import {
  Ion,
  Viewer,
  Camera,
  Rectangle,
  // Cartesian2,
  Cartesian3,
  Color,
  // BillboardCollection,
  // BoundingRectangle,
  // createOsmBuildingsAsync,
  Math as CesiumMath,
  // Terrain,
  // CesiumTerrainProvider,
  // Cesium3DTileset,
  // IonResource,
  Cartographic,
  createGooglePhotorealistic3DTileset,
  GoogleMaps,
  RequestScheduler,
  // ScreenSpaceCameraController,
  HorizontalOrigin,
  VerticalOrigin,
  // ScreenSpaceEventHandler,
  // ScreenSpaceEventType,
  defined,
  ShadowMode,
  HeightReference,
  CameraEventType,
  // NearFarScalar,
  // Cesium3DTileStyle,
  // getServiceArea
  EasingFunction,
  IonImageryProvider,
  BingMapsImageryProvider,
  BingMapsStyle,
} from "cesium"
import "cesium/Build/Cesium/Widgets/widgets.css"
import {onMounted, computed, ref, watch} from "vue"
import {useAppStore} from "../../stores/app.js"
import {useLocationsStore} from "../../stores/locations"
import {storeToRefs} from "pinia"
import {OIcon} from "@jirizavadil/framework.one/ui"

const props = defineProps({
  pins: {
    type: Array,
  },
})

const emit = defineEmits([
  "viewer-mounted",
  "scene-rendered",
  "selected-entity-changed",
  "camera-changed",
  "camera-in",
  "camera-out",
  "pressed-zoom-in",
  "pressed-zoom-out",
  "max-zoom-in",
  "max-zoom-out",
])

// stores
const appStore = useAppStore()
const locationsStore = useLocationsStore()

// states
const {app} = storeToRefs(appStore)
const {locations} = storeToRefs(locationsStore)

const viewer = ref(null)
const pinsAdded = ref(false)
const initialSceneRendered = ref(false)
const locationSelected = computed(() => app.value.mapView.locationSelected)
const lastLocationSelected = ref(null)
const zooming = ref(false)
const imageryLayerProvidersLoaded = ref([])
const style = computed(() => {
  return {
    width: mapViewWidth.value + "px",
    height: mapViewHeight.value + "px",
  }
})
const mapViewWidth = computed(() => {
  let menuWidth = 0

  if (["md", "lg", "xl"].includes(app.value.viewport.currentBreakpoint)) {
    // menuWidth = app.value.menu.visible ? 64 : 0 // -64 is the width of the menu
    return (
      window.innerWidth -
      (app.value.universalDrawer.el ? app.value.universalDrawer.width : 0) -
      menuWidth
    )
  } else {
    return window.innerWidth
  }

  // }
})
const mapViewHeight = computed(() => {
  if (["md", "lg", "xl"].includes(app.value.viewport.currentBreakpoint)) {
    return window.innerHeight
  } else {
    return (
      window.innerHeight -
      (app.value.universalDrawer.el ? app.value.universalDrawer.height : 0) +
      24 // universal drawer radius
    )
  }
})

// config
const initialImageryProvider = "sentinel" // sentinel-2 | bing | google
const secondaryImageryProvider = "bing"
const secondaryImageryProviderTriggerHeight = 20000

// set default view (socotra)
// geometry viewport from google maps
const offset = 40 // zooming closer loads higher-res tiles, so it looks better on phone //77 // zoom out
const northeast = {
  lat: 12.71307960951171,
  lng: 54.53412452363869,
}
const southwest = {
  lat: 12.30132307047588,
  lng: 53.30557793528278,
}

window.CESIUM_BASE_URL = "/cesium"

Ion.defaultAccessToken = import.meta.env.VITE_CESIUM_API_KEY
GoogleMaps.defaultApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

async function init() {
  const rectangle = Rectangle.fromDegrees(
    southwest.lng - offset,
    southwest.lat - offset,
    northeast.lng + offset,
    northeast.lat + offset
  )
  Camera.DEFAULT_VIEW_RECTANGLE = rectangle
  Camera.DEFAULT_VIEW_FACTOR = 0

  const viewerConfig = {
    // 3D terrain
    // terrain: Terrain.fromWorldTerrain({
    //   // requestVertexNormals: true, // throw shadows
    //   // requestWaterMask: true,
    // }),
    // terrainProvider: await CesiumTerrainProvider.fromIonAssetId(1),

    imageryProvider: false,
    animation: false, // Disables the animation widget
    baseLayerPicker: false, // Disables the base layer picker
    fullscreenButton: false, // Disables the fullscreen button
    geocoder: false, // Disables the geocoder widget
    homeButton: false, // Disables the home button
    infoBox: false, // Disables the infobox widget
    sceneModePicker: false, // Disables the scene mode picker
    selectionIndicator: false, // Disables the selection indicator
    timeline: false, // Disables the timeline
    navigationHelpButton: false, // Disables the navigation help button
    navigationInstructionsInitiallyVisible: false, // Disables visibility of navigation instructions initially
    scene3DOnly: true, // Limits scene modes to 3D only
    requestRenderMode: true, // https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/#enabling-request-render-mode
    // useBrowserRecommendedResolution: false, // https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/#enabling-use-browser-recommended-resolution
    // globe: false,
    // skyAtmosphere: false,
    // skyBox: false, // stars
    // contextOptions: {
    //   webgl: {
    //     alpha: true,
    //     // depth: true,
    //     // stencil: false,
    //     // antialias: true,
    //     // premultipliedAlpha: true,
    //     // preserveDrawingBuffer: false,
    //     // failIfMajorPerformanceCaveat: false,
    //   },
    // },
  }

  // create map view
  viewer.value = new Viewer("cesiumContainer", viewerConfig)

  // load initial imagery
  // await loadImagery({provider: initialImageryProvider, initial})
  await switchToImagery({provider: initialImageryProvider})

  emit("viewer-mounted")

  // CONFIG

  // appearance
  viewer.value.scene.skyAtmosphere.show = true
  viewer.value.scene.fog.enabled = true
  viewer.value.scene.highDynamicRange = true // better colors
  viewer.value.scene.globe.showGroundAtmosphere = true
  viewer.value.scene.globe.atmosphereLightIntensity = 3 // overall globe lightness on far distance
  viewer.value.scene.skyAtmosphere.atmosphereLightIntensity = 20 // light contour around globe
  viewer.value.scene.globe.enableLighting = false // sun light (day/night)
  viewer.value.useBrowserRecommendedResolution = false
  viewer.value.resolutionScale = 1
  viewer.value.scene.postProcessStages.fxaa.enabled = true
  // viewer.value.scene.backgroundColor = Color.TRANSPARENT

  // camera
  viewer.value.scene.screenSpaceCameraController.enableTilt = false // disable camera tilt (?)
  viewer.value.scene.screenSpaceCameraController.enableRotate = true // disable camera rotate (drag)
  viewer.value.scene.screenSpaceCameraController.enableLook = false // disable camera look (shift+drag)

  // zoom
  viewer.value.scene.screenSpaceCameraController.enableZoom = true // disable camera zoom (scroll)
  viewer.value.scene.screenSpaceCameraController.maximumZoomDistance = 50000000 // maximum zoom out
  viewer.value.scene.screenSpaceCameraController.minimumZoomDistance = 200 // maximum zoom in
  viewer.value.scene.screenSpaceCameraController._zoomFactor = 20 //16 // override pinch zoom speed (private API https://github.com/CesiumGS/cesium/issues/7978)
  viewer.value.scene.screenSpaceCameraController.zoomEventTypes = [
    // CameraEventType.RIGHT_DRAG,
    // CameraEventType.WHEEL, // handled programatically
    CameraEventType.PINCH,
  ]

  // misc
  viewer.value.scene.debugShowFramesPerSecond = false // debug info
  viewer.value.scene.globe.depthTestAgainstTerrain = false // if this is true, billboards (pins) render poorly even on flat map without terrain

  // one of these causes rendering issues - was attempting to solve pin hole click through
  // https://community.cesium.com/t/selection-of-a-outlined-no-fill-billboard-image/7017
  // https://cesium.com/learn/cesiumjs/ref-doc/Scene.html#pickTranslucentDepth
  // viewer.value.scene.useDepthPicking = true
  // viewer.value.scene.pickTranslucentDepth = true

  // disable camera tilt on zoom (still works with shift+drag)
  Camera.pivotOnCenter = false

  viewer.value.container.addEventListener(
    "wheel",
    function (e) {
      e.preventDefault()
      // console.log("wheel", e.deltaY)
      const speed = 3 * (Math.abs(e.deltaY) / 75)
      if (e.deltaY > 0) {
        zoom("in", speed)
      } else {
        zoom("out", speed)
      }
    },
    {passive: false}
  )
}

async function loadImagery({provider}) {
  // sentinel 2
  if (provider == "sentinel") {
    const sentinel = await IonImageryProvider.fromAssetId(3954)
    viewer.value.imageryLayers.addImageryProvider(sentinel)
  }

  // bing
  else if (provider == "bing") {
    const bing = await BingMapsImageryProvider.fromUrl("https://dev.virtualearth.net", {
      key: import.meta.env.VITE_BING_MAPS_API_KEY,
      mapStyle: BingMapsStyle.AERIAL,
    })

    viewer.value.imageryLayers.addImageryProvider(bing)
  }

  // google
  else if (provider == "google") {
    // Add Google Maps Photorealistic 3D Tiles
    RequestScheduler.requestsByServer["tile.googleapis.com:443"] = 18 // parallel loading: https://developers.google.com/maps/documentation/tile/use-renderer#best-practices
    viewer.value.scene.globe.show = false // not necessary, because 3D tiles include terrain
    try {
      const tileset = await createGooglePhotorealistic3DTileset(null, {
        show: true,
        shadows: ShadowMode.DISABLED,
        maximumScreenSpaceError: 16,
        // dynamicScreenSpaceError: true,

        skipLevelOfDetail: true,
        baseScreenSpaceError: 1024,
        skipScreenSpaceErrorFactor: 16,
        skipLevels: 1,
        immediatelyLoadDesiredLevelOfDetail: false,
        loadSiblings: false,
        cullWithChildrenBounds: true,

        dynamicScreenSpaceError: true,
        dynamicScreenSpaceErrorDensity: 0.00278,
        dynamicScreenSpaceErrorFactor: 4.0,
        dynamicScreenSpaceErrorHeightFalloff: 0.25,

        // debugShowRenderingStatistics: true,
      })

      // tileset loaded event
      tileset.allTilesLoaded.addEventListener(() => {
        // console.log("allTilesLoaded")
        // addPins()
        emit("scene-rendered")
      })

      // listen to tileset requests
      tileset.loadProgress.addEventListener(function (
        numberOfPendingRequests,
        numberOfTilesProcessing
      ) {
        if (numberOfPendingRequests === 0 && numberOfTilesProcessing === 0) {
          // console.log("Stopped loading")
          return
        }

        // console.log(
        //   `Loading: requests: ${numberOfPendingRequests}, processing: ${numberOfTilesProcessing}`
        // )
      })

      viewer.value.scene.primitives.add(tileset)
    } catch (error) {
      console.log(`Error loading Photorealistic 3D Tiles tileset.
  ${error}`)
    }
  }
}

function flyTo({entity, viewport}) {
  // console.log(entity)
  const cartographic = Cartographic.fromCartesian(
    entity.position.getValue(viewer.value.clock.currentTime)
  )
  const latitude = CesiumMath.toDegrees(cartographic.latitude)
  const longitude = CesiumMath.toDegrees(cartographic.longitude)
  // console.log({latitude, longitude})
  // console.log(new Cartesian3(entity.position))
  // console.log(entity.position.getValue(viewer.value.clock.currentTime))

  // viewer.value.scene.camera.lookAt(
  //   entity.position.getValue(viewer.value.clock.currentTime),
  //   new Cartesian3(0, 0, 4000000)
  // )

  let destination = null

  // we have area
  if (viewport) {
    // console.log("got viewport:", viewport)
    const offset = 0 // zoom out
    const northeast = {
      lat: viewport.northeast.lat,
      lng: viewport.northeast.lng,
    }
    const southwest = {
      lat: viewport.southwest.lat,
      lng: viewport.southwest.lng,
    }
    const rectangle = Rectangle.fromDegrees(
      southwest.lng - offset,
      southwest.lat - offset,
      northeast.lng + offset,
      northeast.lat + offset
    )
    // console.log({rectangle})
    destination = rectangle
  }
  // we have just a single location
  else {
    const height = 1000
    destination = Cartesian3.fromDegrees(longitude, latitude, height)
  }

  // https://cesium.com/learn/ion-sdk/ref-doc/Camera.html?classFilter=camera#flyTo
  viewer.value.camera.flyTo({
    destination,
    orientation: {
      // heading: CesiumMath.toRadians(0.0),
      // pitch: CesiumMath.toRadians(-15.0),
    },
    // offset: new Cesium.HeadingPitchRange(Cesium.Math.toRadians(90), Cesium.Math.toRadians(-25), 1200);
    duration: 3,
    easingFunction: EasingFunction.QUADRATIC_IN_OUT,
  })
}

function addPins() {
  if (pinsAdded.value || !props.pins) {
    return
  }
  // const prefix = import.meta.env.VITE_ENVIRONMENT === "local_dev" ? "/public/assets" : ""
  // const atlaseekPin = `${prefix}/elements/pin/pin-red.svg`
  // const atlaseekPin = `${prefix}/elements/pin/pin-red.png`
  const atlaseekPin = `/elements/pin/pin-red-clickable-transparency.png`

  props.pins.forEach((pin) => {
    addPin({
      latitude: pin.position.lat,
      longitude: pin.position.lng,
      name: pin.name,
      image: atlaseekPin,
    })
  })
}

function addPin({latitude, longitude, name, image}) {
  if (!latitude || !longitude) {
    console.error("missing latitude or longitude")
    return
  }

  // console.log({latitude, longitude, name})
  // color dot
  // viewer.value.entities.add({
  //   position: Cartesian3.fromDegrees(-75.59777, 40.03883),
  //   point: {
  //     pixelSize: 10,
  //     color: Color.YELLOW,
  //   },
  // })

  // atlaseek pin
  viewer.value.entities.add({
    // position: Cartesian3.fromDegrees(longitude, latitude, 199.7162017822266),
    id: name.id,
    position: Cartesian3.fromDegrees(longitude, latitude),
    name,
    billboard: {
      image,
      horizontalOrigin: HorizontalOrigin.RIGHT,
      verticalOrigin: VerticalOrigin.BOTTOM,
      show: true,
      // scale: 0.3
      width: 21,
      height: 21,
      // position: new Cartesian3(0, 0, 0),
      // heightReference: HeightReference.RELATIVE_TO_GROUND,
      heightReference: HeightReference.CLAMP_TO_GROUND,
      // pixelOffset: new Cartesian2(0, 0), // default: (0, 0)
      // eyeOffset: new Cartesian3(0,0, 10000) //new Cartesian3(0, 0, -10000000)  // new Cartesian3(0.0, 0.0, 0.0), // default
      // disableDepthTestDistance: Number.POSITIVE_INFINITY
    },
  })

  // const handler = new ScreenSpaceEventHandler(viewer.value.scene.canvas)
  // handler.setInputAction((click) => {
  //   // pick a feature
  //   const pickedFeature = viewer.value.scene.pick(click.position)

  //   console.log({pickedFeature})

  //   // specify your if-else-condition
  //   // const condition = 'URL OF SPECIFIC TILESET'
  // }, ScreenSpaceEventType.LEFT_CLICK)
}

// function adjustLayerColors({colors, currentHeight}) {
//   const imageryLayers = viewer.value.imageryLayers
//   console.log("adjustLayerColors", colors, currentHeight)

//   if (imageryLayers.length > 0) {
//     const layer = imageryLayers.get(0)
//     layer.brightness = 1.46
//     layer.contrast = 1.14
//     layer.hue = 0
//     layer.saturation = 2.04
//     layer.gamma = 0.66
//     viewer.value.scene.requestRender()
//     console.log("rerendering")
//   }
// }

function getCameraTerrainHeight() {
  const camera = viewer.value.camera
  const scratchCartesian = new Cartesian3()
  const pos = camera.positionWC.clone(scratchCartesian)
  const height = Cartographic.fromCartesian(pos).height
  // console.log("camera height:", height)
  return height
}

async function switchToImagery({provider}) {
  // console.log("switchToImagery", {provider})

  // if top layer is requested provider, do nothing
  const topLayer = imageryLayerProvidersLoaded.value[imageryLayerProvidersLoaded.value.length - 1]
  if (topLayer == provider) return

  // console.log({topLayer})

  // if requested provider is not loaded, load it
  if (!imageryLayerProvidersLoaded.value.includes(provider)) {
    imageryLayerProvidersLoaded.value.push(provider) // its not really loaded yet, but i need to add i asap or it'll be added multiple times when scrolling fast
    // console.log("requesting provider load:", provider)
    await loadImagery({provider})
  }

  // make the switch
  const imageryLayers = viewer.value.imageryLayers
  const providerIndex = imageryLayerProvidersLoaded.value.indexOf(provider)
  const imageryLayer = viewer.value.imageryLayers.get(providerIndex)

  if (!imageryLayer) return

  imageryLayers.raiseToTop(imageryLayer)
  const index = imageryLayerProvidersLoaded.value.indexOf(provider)
  if (index !== -1) {
    imageryLayerProvidersLoaded.value.splice(index, 1)
    imageryLayerProvidersLoaded.value.push(provider)
  }

  adjustImageryLayerColors({provider})
  imageryLayer.show = true

  setTimeout(() => {
    // hide all others
    for (let i = 0; i < imageryLayerProvidersLoaded.value.length; i++) {
      if (imageryLayerProvidersLoaded.value[i] !== provider) {
        imageryLayers.get(i).show = false
      }
    }
  }, 1000)
}

function adjustImageryLayerColors({provider}) {
  // console.log("adjustImageryLayerColors", provider)
  // sentinel
  if (provider == "sentinel") {
    // adjust colors
    const imageryLayers = viewer.value.imageryLayers
    const providerIndex = imageryLayerProvidersLoaded.value.indexOf("sentinel")
    // console.log({providerIndex})

    if (imageryLayers.length > 0) {
      const layer = imageryLayers.get(providerIndex)
      // console.log({layer})
      layer.brightness = 1
      layer.contrast = 1.15
      layer.hue = 0
      layer.saturation = 0.6
      layer.gamma = 0.9
      viewer.value.scene.requestRender()
      // console.log("rerendering")
    }
  }

  // bing
  else if (provider == "bing") {
    // adjust colors
    const imageryLayers = viewer.value.imageryLayers
    const providerIndex = imageryLayerProvidersLoaded.value.indexOf("bing")
    console.log({providerIndex, imageryLayerProvidersLoaded: imageryLayerProvidersLoaded.value})
    if (imageryLayers.length > 0) {
      const layer = imageryLayers.get(providerIndex)
      console.log({layer})
      layer.brightness = 1
      layer.contrast = 1
      layer.hue = 0
      layer.saturation = 1
      layer.gamma = 1
      viewer.value.scene.requestRender()
      // console.log("rerendering")
    }
  }
}

function listenToTileLoad() {
  viewer.value.scene.globe.tileLoadProgressEvent.addEventListener(function (queuedTileCount) {
    // console.log({queuedTileCount})
    // console.log(viewer.value.scene.globe.tilesLoaded)

    if (viewer.value.scene.globe.tilesLoaded) {
      // console.log("tiles loaded")
      emit("scene-rendered")

      // initial scene?
      if (!initialSceneRendered.value) {
        initialSceneRendered.value = true
        addPins()
      }
    }
  })
}

function listenToEntitySelection() {
  viewer.value.selectedEntityChanged.addEventListener(function (selectedEntity) {
    if (defined(selectedEntity)) {
      app.value.mapView.locationSelected = {
        ...selectedEntity.name,
        _trigger: "mapPinClick",
      }
      emit("selected-entity-changed", selectedEntity)
    } else {
      app.value.mapView.locationSelected = null
      emit("selected-entity-changed", null)
    }
  })
}

function listenToCameraPosition() {
  const camera = viewer.value.camera

  let startHeight
  let endHeight

  camera.changed.addEventListener(function () {
    const currentHeight = getCameraTerrainHeight()
    // console.log({currentHeight})
    if (currentHeight <= secondaryImageryProviderTriggerHeight) {
      switchToImagery({provider: secondaryImageryProvider})
    } else if (currentHeight > secondaryImageryProviderTriggerHeight) {
      switchToImagery({provider: initialImageryProvider})
    }
    emit("camera-changed", {currentHeight})
  })

  camera.moveStart.addEventListener(function () {
    startHeight = getCameraTerrainHeight()
  })

  camera.moveEnd.addEventListener(function () {
    endHeight = getCameraTerrainHeight()

    if (startHeight > endHeight) {
      emit("camera-in", {currentHeight: endHeight})
      // console.log("zoom in")
    } else {
      emit("camera-out", {currentHeight: endHeight})
      // console.log("zoom out")
    }
  })
}

function highlightPin({selectedEntity}) {
  selectedEntity.billboard.image = `/elements/pin/pin-red-group-empty.png`
  viewer.value.scene.requestRender()
}

function markPinVisited({selectedEntity}) {
  // console.log(markPinVisited, {selectedEntity})
  selectedEntity.billboard.image = `/elements/pin/pin-red-clickable-transparency.png`
  viewer.value.scene.requestRender()
}

function onPressZoom(direction, action) {
  // console.log("onPressZoom", {direction, action}, zooming.value)

  if (action === "start") {
    if (direction == "in") {
      emit("pressed-zoom-in")
    } else if (direction == "out") {
      emit("pressed-zoom-out")
    }
    if (!zooming.value) {
      zooming.value = setInterval(() => {
        zoom(direction)
      }, 10)
    }
  } else {
    // console.log("clearing interval")
    clearInterval(zooming.value)
    zooming.value = null
  }
}

function getZoomFactor(altitude) {
  // Adjust these values as needed for the desired effect
  const minAltitude = 200 // Minimum altitude where zoom factor is applied
  const maxAltitude = 50000000 // Maximum altitude where zoom factor is applied
  const minZoomFactor = 0.1 // Minimum zoom factor (slowest zoom)
  const maxZoomFactor = 1000000 // Maximum zoom factor (fastest zoom)

  const t = (altitude - minAltitude) / (maxAltitude - minAltitude)
  return (1.0 - t) * minZoomFactor + t * maxZoomFactor
}

function zoom(direction, speed = 1) {
  const camera = viewer.value.camera
  const currentHeight = getCameraTerrainHeight()
  const zoomFactor = getZoomFactor(currentHeight) * speed
  // console.log("zoom", {direction, zoomFactor, speed})
  if (direction === "in" && currentHeight > 200) {
    camera.zoomIn(zoomFactor)
    emit("camera-in", {currentHeight})
  } else if (direction === "in" && currentHeight <= 200) {
    emit("max-zoom-in")
  } else if (direction === "out" && currentHeight < 50000000) {
    camera.zoomOut(zoomFactor)
    emit("camera-out", {currentHeight})
  } else if (direction === "out" && currentHeight >= 50000000) {
    emit("max-zoom-out")
  }
}

// watch location change in store and fly to location
watch(
  () => locationSelected.value,
  (newValue) => {
    if (newValue) {
      // lookup entity with location
      const entity = viewer.value.entities.getById(newValue.id)
      let viewport = null
      // console.log({entity})

      // lookup location details
      if (locations.value) {
        const location = locations.value.find((location) => location.id === newValue.id)
        if (location) {
          // console.log({location})
          if (location.googleMaps?.geometry?.viewport) {
            viewport = location.googleMaps.geometry.viewport
          }
        }
      }

      highlightPin({selectedEntity: entity})

      // do not move tha map during development
      // if (import.meta.env.VITE_ENVIRONMENT === "production") {
      flyTo({entity, viewport})
      // }
    }

    // dim previous pin
    if (lastLocationSelected.value && lastLocationSelected.value.id !== newValue?.id) {
      // console.log({lastLocationSelected: lastLocationSelected.value})
      const lastSelectedEntity = viewer.value.entities.getById(lastLocationSelected.value?.id)
      markPinVisited({selectedEntity: lastSelectedEntity})
    }

    lastLocationSelected.value = locationSelected.value
  }
)

onMounted(() => {
  style.value = {
    height: 100 + "px",
  }

  init()
  listenToTileLoad()
  listenToEntitySelection()
  listenToCameraPosition()
})
</script>

<template>
  <div class="" id="cesiumContainer" :style="style">
    <!-- zoom control -->
    <div
      class="zoom-control fixed bottom-28 right-4 z-10 flex flex-col gap-4 md:bottom-4"
      v-if="app.mapView.showZoomControls"
    >
      <div
        class="zoom-control flex h-11 w-11 cursor-pointer rounded-full bg-white bg-opacity-10 transition-all duration-300 ease-in-out flex-center hover:bg-opacity-20"
        @mousedown="onPressZoom('in', 'start')"
        @mouseup="onPressZoom('in', 'stop')"
        @touchstart.passive="onPressZoom('in', 'start')"
        @touchend.passive="onPressZoom('in', 'stop')"
      >
        <OIcon lucide="Plus" />
      </div>
      <div
        class="zoom-control flex h-11 w-11 cursor-pointer rounded-full bg-white bg-opacity-10 transition-all duration-300 ease-in-out flex-center hover:bg-opacity-20"
        @mousedown="onPressZoom('out', 'start')"
        @mouseup="onPressZoom('out', 'stop')"
        @touchstart.passive="onPressZoom('out', 'start')"
        @touchend.passive="onPressZoom('out', 'stop')"
      >
        <OIcon lucide="Minus" />
      </div>
    </div>

    <!-- legal -->
    <span class="fixed bottom-3 right-20 z-10 text-xs text-white opacity-75 hidden">PLGRND L.L.C-FZ</span>
  </div>
</template>

<style>
/* prevent random text selection on touch devices*/
canvas,
.zoom-control {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.cesium-performanceDisplay-defaultContainer {
  top: 15px !important;
  right: 80px !important;
}

.cesium-widget-credits {
  display: none !important;
}

@media screen(md) {
  .cesium-viewer-bottom {
    left: 72px !important;
    bottom: 8px !important;
  }

  .cesium-viewer-bottom .cesium-widget-credits {
    display: flex !important;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  .cesium-viewer-bottom .cesium-credit-textContainer {
    padding-bottom: 3px;
  }

  .cesium-viewer-bottom .cesium-credit-expand-link {
    padding-bottom: 3px;
  }
}
</style>
