<script setup>
import {useAppStore} from "../stores/app"
import {storeToRefs} from "pinia"
import {OIcon} from "@jirizavadil/framework.one/ui"

const appStore = useAppStore()
const {app} = storeToRefs(appStore)
</script>

<template>
  <div
    class="fixed left-2 right-2 z-[5000] m-auto flex max-w-xs flex-row items-center gap-3 rounded-xl bg-brown-700 px-4 py-2 transition-all duration-300 ease-in-out"
    :class="[
      {'top-2 opacity-100': app.notification.active},
      {'-top-40 opacity-0': !app.notification.active},
    ]"
  >
    <OIcon class="shrink-0 text-sand-300" lucide="AlertTriangle" />
    <div class="">
      <span class="font-bold text-white">{{ app.notification.title }}</span>
      <p class="text-sand">{{ app.notification.message }}</p>
    </div>
  </div>
</template>
