import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc, serverTimestamp, updateDoc} from "firebase/firestore"

export const useUserStore = defineStore("user", () => {
  // state
  const user = ref({})
  const userSignedIn = ref(false)
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const userLoaded = computed(() => status.value == "loaded")
  const userId = computed(() => user.value.id)
  const userRoles = computed(() => user.value?.channels?.explore?.roles || [])
  const userPlan = computed(() => user.value.plan)
  const userIsAdmin = computed(() => !!userRoles.value?.includes("admin"))
  const userIsCreator = computed(() => !!userRoles.value?.includes("creator"))
  const userIsExplorer = computed(() => !!userRoles.value?.includes("explorer"))

  // actions
  async function bind(args) {
    const uid = args.userId
    // console.log('user bind:', uid)
    const docRef = doc(firestore, `users/${uid}`)
    return await this.attach("user", docRef)
  }

  async function unbind() {
    console.log("unbind")
    return await this.detach()
  }

  function userAllowed(featureRoles) {
    if (!userSignedIn.value || !userLoaded.value || !userRoles?.value) return false
    return userRoles.value.some((item) => featureRoles.includes(item))
  }

  async function update(args) {
    const data = args.data
    const uid = args.userId || userId.value
    console.log({uid})
    const userRef = doc(firestore, `users/${uid}`)
    const updateData = {
      ...data,
      "meta.updated": serverTimestamp(),
    }
    console.log({updateData})
    return await updateDoc(userRef, updateData)
  }

  return {
    // state
    user,
    userSignedIn,
    status,
    unsubscribe,

    // getters
    userLoaded,
    userId,
    userRoles,
    userPlan,
    userIsAdmin,
    userIsCreator,
    userIsExplorer,

    // actions
    bind,
    unbind,
    userAllowed,
    update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
