import anime from "animejs/lib/anime.es.js"

export default {
  install: (app) => {
    app.directive("anime", {
      beforeMount: function beforeMount(targets, binding) {
        let mergeOpts = {
          targets,
          autoplay: binding.value.autoplay ? binding.value.autoplay : false,
        }
        let opts = Object.assign({}, binding.value, mergeOpts)
        let onceFired = false // when firing only once
        let options = {
          root: null,
          // rootMargin: '0px',
          threshold: 0, // as soon as 1 px visible
          trackVisibility: true,
          delay: 500,
        }

        if (binding.arg == "visible") {
          let animation = anime(opts)

          let observer = new IntersectionObserver((entries) => {
            if (onceFired) return

            let entry = entries[0] // there is always only one entry
            let isVisible = entry.isIntersecting

            if (isVisible) {
              onceFired = true
              animation.play()
            }
          }, options)

          observer.observe(targets)
        } else {
          targets.anime = anime(opts)
        }
      },
    })

    app.config.globalProperties.$anime = anime
  },
}
